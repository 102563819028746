// 2s to show the toast message.
// PrimeVue doesn't have a global setting for this
export const TOAST_LIFE = 3000;
export const DATE_FORMAT = "yyyy-MM-dd";

// Onboarding events
export const EVENT_ONBOARDING_NEXT_STEP = "onboarding_nextStep";
export const EVENT_ONBOARDING_PREV_STEP = "onboarding_prevStep";
export const EVENT_ONBOARDING_COMPLETE = "onboarding_complete";

// Language events
// Fired when language changes (eg. LanguageSelect)
export const EVENT_LANGUAGE_CHANGE = "language_change";
// Fired AFTER a new language model was imported, uploaded, and cached.
export const EVENT_LANGUAGE_CHANGE_COMPLETE = "language_change_complete";
