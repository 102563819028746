<script>
import NotificationCard from "@/components/ui/NotificationCard.vue";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "StartSellingView",
  components: {
    BaseButton,
    NotificationCard,
  },
  mounted() {
    this.$nextTick(function () {
      this.$emit("complete");
    });
  },
};
</script>

<template>
  <NotificationCard icon="check">
    <template #title> {{ $t("informationCard.startSelling.title") }}</template>
    <template #description>
      <p>
        {{ $t("informationCard.startSelling.content") }}
      </p>
    </template>
    <BaseButton
      :label="$t('button.startSelling')"
      iconPos="right"
      icon="pi pi-angle-right"
      class="p-button-sm w-full text-center"
    />
  </NotificationCard>
</template>
