<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import BaseCard from "@/components/ui/BaseCard";
import NotificationCard from "@/components/ui/NotificationCard.vue";
import ContactUsEmailAddress from "@/components/ui/ContactUsEmailAddress.vue";

export default {
  name: "EmailVerificationLinkExpiredView",
  components: {
    BaseCard,
    BaseButton,
    NotificationCard,
    ContactUsEmailAddress,
  },
};
</script>
<template>
  <div>
    <NotificationCard icon="warning-circle">
      <template #title>{{
        $t("informationCard.emailVerificationLinkExpired.title")
      }}</template>
      <template #description>
        <p>
          {{ $t("informationCard.emailVerificationLinkExpired.content") }}
          <br />
          <br />
          <i18n path="informationCard.emailVerificationLinkExpired.description">
            <template #email>
              <span class="font-semibold">labas@rimti.lt</span>
            </template>
          </i18n>
        </p>
      </template>
      <template #footer>
        <BaseButton
          :label="$t('button.resendEmail')"
          class="p-button p-button-sm p-button-primary w-full"
        />
        <BaseCard
          intent="default"
          variant="alert"
          alertIcon="question-fill"
          :alertIconSmall="true"
          padding="md"
        >
          <template #alert-label>{{
            $t("informationCard.didntReceiveAnEmail.title")
          }}</template>
          <template #alert-content-alternate>
            <i18n path="informationCard.didntReceiveAnEmail.content" tag="p">
              <template #contactEmailAddress>
                <ContactUsEmailAddress />
              </template>
            </i18n>
          </template>
        </BaseCard>
      </template>
    </NotificationCard>
  </div>
</template>
