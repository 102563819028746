<script>
import BaseMultiselect from "@/components/ui/BaseMultiselect";

export default {
  name: "ProductCategoriesMultiselect",
  extends: BaseMultiselect,
  components: {
    BaseMultiselect,
  },
  computed: {
    productCategories() {
      return [
        { label: this.$t("productCategories.mobilePhones"), value: "mobile" },
        { label: this.$t("productCategories.tv"), value: "tv" },
        {
          label: this.$t("productCategories.computers"),
          value: "computers",
        },
        {
          label: this.$t("productCategories.headphones"),
          value: "headphones",
        },
        {
          label: this.$t("productCategories.accessories"),
          value: "accessories",
        },
      ];
    },
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>

<template>
  <BaseMultiselect
    data-component="product-category-multiselect"
    v-bind="props"
    v-on="$listeners"
    :options="productCategories"
    :placeholder="$t('input.productCategories.placeholder')"
    optionLabel="label"
    optionValue="value"
  />
</template>
