<script>
import { isInternalFlow } from "./lib/utils";

export default {
  name: "App",
  computed: {
    layoutComponent() {
      const layoutName = this.$route.meta.layout;
      const isInFlowRoute = isInternalFlow(this);
      const layoutComponentToLoad = isInFlowRoute
        ? "InternalFlowLayout"
        : layoutName || "BaseLayout";
      return require(`@/components/layouts/${layoutComponentToLoad}.vue`)
        .default;
    },
  },
};
</script>

<template>
  <main id="app">
    <component v-bind:is="layoutComponent">
      <router-view></router-view>
    </component>
  </main>
</template>
