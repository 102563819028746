<script>
import { EVENT_LANGUAGE_CHANGE } from "@/lib/constants";
import { EventBus } from "@/lib/event-bus";
import { cn } from "@/lib/utils";
import { i18n } from "@/setup/i18n-setup";
import { configure, extend, ValidationObserver } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

configure({
  // https://github.com/logaretm/vee-validate/blob/3.3.1/docs/guide/localization.md
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

Object.keys(rules).forEach((rule) => {
  extend(rule, { ...rules[rule] });
});

export default {
  name: "BaseForm",
  extends: ValidationObserver,
  components: {
    ValidationObserver,
  },
  props: {
    onSubmit: {
      type: Function,
    },
    formContentClassName: {
      type: String,
    },
    defaultSpacing: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formContentClassNameComputed() {
      return cn(
        this.defaultSpacing ? "space-y-4" : "",
        this.formContentClassName
      );
    },
  },
  mounted() {
    this.$nextTick(function () {
      EventBus.$on(EVENT_LANGUAGE_CHANGE, () => {
        // Reset the validation state when language changes
        this.$refs.validator.reset();
      });
    });
  },
  beforeDestroy() {
    EventBus.$off(EVENT_LANGUAGE_CHANGE);
  },
};
</script>

<template>
  <ValidationObserver ref="validator" v-slot="{ handleSubmit }" tag="div">
    <form
      @submit.prevent="handleSubmit(onSubmit)"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div :class="formContentClassNameComputed">
        <slot name="form-content"></slot>
      </div>
      <slot name="form-footer"></slot>
    </form>
  </ValidationObserver>
</template>
