<script>
import PrimeCheckbox from "primevue/checkbox";

export default {
  name: "BaseCheckbox",
  extends: PrimeCheckbox,
  components: {
    PrimeCheckbox,
  },
  computed: {
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
  },
};
</script>

<template>
  <PrimeCheckbox v-bind="parentProps" v-on="$listeners" />
</template>
