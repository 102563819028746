<script>
import LoginForm from "@/components/forms/LoginForm";
import BaseCard from "../ui/BaseCard.vue";

export default {
  name: "LoginPage",
  components: { LoginForm, BaseCard },
  data() {
    return {
      isEmailLinkExpired: this.$route?.query?.isEmailLinkExpired === "true",
    };
  },
};
</script>

<template>
  <div>
    <BaseCard
      v-if="isEmailLinkExpired"
      class="mb-6"
      intent="warning"
      variant="alert"
      shadow="true"
      alertIcon="warning-circle-fill"
      alertIconClassName="[&_path]:fill-warning"
      padding="sm"
      alert="default"
    >
      <template #alert-label>
        {{ $t("alertCard.emailVerificationLinkExpired.title") }}
      </template>
      <template #alert-content>
        {{ $t("alertCard.emailVerificationLinkExpired.content") }}
      </template>
    </BaseCard>
    <LoginForm />
  </div>
</template>
