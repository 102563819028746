export default {
  vatNumber: null,
  personalCode: null,
  dateOfBirth: null,
  files: [],
  addressOfRegistration: null,
  bankAccount: null,
  bankName: null,
  productCategories: null,
  saleCountries: null,
  numberOfSKUs: null,
  shippingMethod: null,
  deliveryAddress: null,
  contractSigneeFullName: null,
  representativeDuties: null,
  contactPersonPerformanceAgreementsFullName: null,
  contactPersonPerformanceAgreementsDuties: null,
  contactPersonPerformanceAgreementsEmail: null,
  contactPersonPerformanceAgreementsPhone: null,
};
