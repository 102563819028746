<script>
import BaseAvatar from "@/components/ui/BaseAvatar";
import BaseCard from "@/components/ui/BaseCard";
import BaseIcon from "@/components/ui/BaseIcon";
import BaseSeparator from "@/components/ui/BaseSeparator";

export default {
  name: "NotificationCard",
  components: {
    BaseCard,
    BaseAvatar,
    BaseIcon,
    BaseSeparator,
  },
  props: {
    icon: {
      type: String,
    },
  },
};
</script>

<template>
  <BaseCard class="space-y-4 md:space-y-6" shadow="true">
    <BaseAvatar>
      <BaseIcon
        :icon="icon"
        class="w-6 invert md:w-8 md:[&_svg]:h-8 md:[&_svg]:w-8"
      />
    </BaseAvatar>
    <h3>
      <slot name="title"></slot>
    </h3>
    <div class="space-y-4 md:space-y-6">
      <slot name="description"></slot>
    </div>
    <slot></slot>
    <BaseSeparator v-if="$slots.footer" />
    <slot name="footer"></slot>
  </BaseCard>
</template>
