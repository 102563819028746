<script>
import BaseFileUpload from "@/components/ui/BaseFileUpload.vue";

export default {
  name: "DocumentsFileUpload",
  extends: BaseFileUpload,
  components: {
    BaseFileUpload,
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>
<template>
  <BaseFileUpload
    v-bind="props"
    v-on="$listeners"
    name="files[]"
    rules="required"
    accept="pdf,doc"
    :fileLimit="3"
    :maxFileSize="10000000"
  />
</template>
