<script>
import BaseCard from "@/components/ui/BaseCard";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import prettyBytes from "pretty-bytes";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "BaseFileCard",
  components: {
    BaseCard,
    BaseIcon,
    BaseButton,
  },
  props: {
    fileName: {
      type: String,
      required: true,
    },
    fileSize: {
      type: Number,
    },
    content: {
      type: String,
    },
    download: {
      type: String,
    },
  },
  computed: {
    formattedFileSize() {
      return prettyBytes(this.fileSize);
    },
  },
  methods: {
    handleDownload() {
      this.$refs.link.click();
    },
  },
};
</script>

<template>
  <BaseCard
    variant="light"
    shadow="true"
    padding="none"
    class="flex gap-3 p-3 transition-colors hover:border-primary"
  >
    <div class="h-5 w-5">
      <BaseIcon icon="file" />
    </div>
    <div class="flex-1 space-y-1">
      <div class="text-sm">{{ fileName }}</div>
      <div class="text-xs text-neutral-foreground">
        <span v-if="fileSize">{{ formattedFileSize }}</span>
        <span v-else>{{ content }}</span>
      </div>
    </div>
    <div>
      <div v-if="!$slots.buttons && download">
        <BaseButton
          :label="$t('button.download')"
          iconPos="left"
          icon="pi pi-download"
          class="p-button-secondary !h-8 px-3 py-1 text-sm"
          @click.prevent="handleDownload"
        />
        <a :href="download" target="_blank" ref="link" class="hidden"></a>
      </div>
      <slot name="buttons"></slot>
    </div>
  </BaseCard>
</template>
