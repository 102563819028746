export default {
  copmanyType: null,
  companyCertificateNumber: null,
  companyCode: null,
  companyName: null,
  registrationCountry: null,
  phoneNumber: null,
};

export const companyDetailsOutboundSchema = {
  name: null,
  website: null,
};

export const companyDetailsInboundSchema = {
  ...companyDetailsOutboundSchema,
  website: null,
};
