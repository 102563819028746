<script>
export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
    },
    width: {
      type: String,
    },
    format: {
      type: String,
      default: "svg",
    },
    toSvg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      src: this.getIcon(),
    };
  },
  watch: {
    icon: "setIcon",
  },
  computed: {
    resolvedIcon() {
      if (!this.icon) {
        return;
      }
      return require(`@/assets/images/icons/${this?.icon}.${this?.format}`);
    },
  },
  methods: {
    getIcon() {
      if (!this.icon) {
        return;
      }

      return require(`@/assets/images/icons/${this?.icon}.${this?.format}`);
    },
    setIcon() {
      this.src = this.getIcon();
    },
  },
};
</script>

<template>
  <span>
    <img
      v-if="format === 'svg' && toSvg"
      v-svg-inline
      v-bind="$attrs"
      :src="resolvedIcon"
      :alt="icon"
      class="w-full"
      :width="width"
    />
    <img
      v-else
      v-bind="$attrs"
      :src="resolvedIcon"
      :alt="icon"
      class="w-full"
      :width="width"
    />
  </span>
</template>
