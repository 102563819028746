/**
 * @description A map of file types for the File Upload component.
 * Key represents the accepted file type.
 * Value represents all possible/accepted MIME types for that file.
 * In BaseFileUpload component you can reference the keys of this object in the "accept" property.
 * <BaseFileUpload accept="doc"/>
 */
export const fileTypes = {
  pdf: "application/pdf",
  doc: "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};
