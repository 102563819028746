<script>
import BaseRadio from "@/components/ui/BaseRadio";
import BaseLabel from "@/components/ui/BaseLabel";
import { ValidationProvider } from "vee-validate";
import FormMessage from "@/components/ui/FormMessage";

export default {
  name: "RadioButtonWithLabel",
  extends: BaseRadio,
  components: {
    BaseRadio,
    BaseLabel,
    ValidationProvider,
    FormMessage,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
    },
  },
  computed: {
    baseRadioProps() {
      return { ...this.$props, ...this.$attrs };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    class="space-y-1"
  >
    <BaseLabel
      :for="$attrs?.id"
      class="input group flex cursor-pointer items-center justify-between gap-2 bg-white px-3 py-2 transition-colors hover:border-primary has-[:checked]:border-primary"
    >
      <span>{{ label }}</span>
      <BaseRadio
        v-bind="baseRadioProps"
        v-on="$listeners"
        class="group-hover:[&>.p-radiobutton-box]:border-primary group-hover:[&>.p-radiobutton-box]:shadow-focus"
      />
    </BaseLabel>
    <FormMessage v-if="errors[0]" intent="danger">{{ errors[0] }}</FormMessage>
  </ValidationProvider>
</template>
