<script>
import BaseBadge from "@/components/ui/BaseBadge.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import BaseCard from "@/components/ui/BaseCard.vue";
import BaseSeparator from "@/components/ui/BaseSeparator.vue";
import TitleGroup from "@/components/ui/TitleGroup.vue";
import BaseFileCard from "@/components/ui/BaseFileCard.vue";
import DocumentsFileUpload from "@/components/ui/DocumentsFileUpload.vue";
import BaseForm from "@/components/ui/BaseForm.vue";

export default {
  name: "DownloadAndSignAgreementsView",
  components: {
    BaseBadge,
    TitleGroup,
    BaseCard,
    BaseSeparator,
    BaseButton,
    BaseFileCard,
    DocumentsFileUpload,
    BaseForm,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    async nextPage() {
      const isvalid = await this.$refs.form.validate();
      if (!isvalid) {
        return;
      }
      this.$emit("nextPage", {
        pageIndex: 2,
        completed: true,
        query: { canStartSelling: false },
      });
    },
  },
};
</script>

<template>
  <div class="space-y-6">
    <div class="space-y-4">
      <BaseBadge variant="secondary">{{
        $t("content.signAgreements")
      }}</BaseBadge>
      <TitleGroup
        :large="true"
        :title="$t('content.downloadAndSignAgreements')"
        :subtitle="$t('content.downloadAndSignAgreementsSubtitle')"
      />
    </div>
    <BaseCard shadow="true" variant="light" footerClassName="justify-end">
      <template #content>
        <BaseForm
          formContentClassName="space-y-4 md:space-y-6"
          :defaultSpacing="false"
          ref="form"
        >
          <template #form-content>
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>1. {{ $t("content.downloadAgreements") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.downloadAgreementsSubtitle") }}
                </p>
              </div>
              <div class="space-y-4">
                <BaseFileCard
                  :fileName="$t('content.sellerAgreement')"
                  :content="$t('content.uploaded', { date: '2024-05-18' })"
                  download="https://docs.google.com/uc?id=0B0jH18Lft7ypSmRjdWg1c082Y2M"
                />
                <BaseFileCard
                  :fileName="$t('content.commercialAgencyAgreement')"
                  :content="$t('content.uploaded', { date: '2024-05-18' })"
                  download="https://docs.google.com/uc?id=0B0jH18Lft7ypSmRjdWg1c082Y2M"
                />
                <BaseFileCard
                  :fileName="$t('content.dataProcessingAgreement')"
                  :content="$t('content.uploaded', { date: '2024-05-18' })"
                  download="https://docs.google.com/uc?id=0B0jH18Lft7ypSmRjdWg1c082Y2M"
                />
              </div>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>2. {{ $t("content.printAndSignAgreements") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.printAndSignAgreementsSubtitle") }}
                </p>
              </div>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>3. {{ $t("content.uploadSignedAgreements") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.uploadSignedAgreementsSubtitle") }}
                </p>
              </div>
              <DocumentsFileUpload
                :fieldName="$t('input.signedAgreements.fieldName')"
                @change="(val) => (files = val)"
              />
            </div>
          </template>
        </BaseForm>
      </template>
      <template #footer>
        <BaseButton
          :label="$t('button.sendAgreements')"
          class="p-button p-button-sm w-full text-center md:w-auto"
          @click="nextPage"
        >
        </BaseButton>
      </template>
    </BaseCard>
  </div>
</template>
