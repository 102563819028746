<script>
export default {
  name: "TitleGroup",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div :class="large ? 'space-y-4' : 'space-y-2'">
    <h1>{{ title }}</h1>
    <p class="text-neutral-foreground" v-if="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>
