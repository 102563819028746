<script>
import DownloadAndSignAgreementsView from "./DownloadAndSignAgreementsView.vue";
import SubmissionReceivedView from "./SubmissionReceivedView.vue";

export default {
  name: "DownloadAndSignAgreementsWrapperView",
  components: {
    SubmissionReceivedView,
    DownloadAndSignAgreementsView,
  },
  data() {
    return {
      // onboaridng/step/<...>?isSubmissionReviewed=true
      isSubmissionReviewed: this.$route.query?.isSubmissionReviewed
        ? this.$route.query?.isSubmissionReviewed === "true"
        : false,
    };
  },
};
</script>

<template>
  <SubmissionReceivedView v-if="!isSubmissionReviewed" v-on="$listeners" />
  <DownloadAndSignAgreementsView v-else v-on="$listeners" />
</template>
