<script>
import BaseBadge from "@/components/ui/BaseBadge";
import BaseButton from "@/components/ui/BaseButton";
import BaseForm from "@/components/ui/BaseForm.vue";
import BaseLabel from "@/components/ui/BaseLabel";
import BasePasswordInput from "@/components/ui/BasePasswordInput";
import BaseTextInput from "@/components/ui/BaseTextInput";
import DataProcessingAgreementCheckbox from "@/components/ui/DataProcessingAgreementCheckbox";
import FormGroup from "@/components/ui/FormGroup";
import LoginOrCreateAccountMessageLink from "@/components/ui/LoginOrCreateAccountMessageLink";
import TitleGroup from "@/components/ui/TitleGroup";
import { signIn } from "@/lib/utils";
import dataProcessingSchema from "@/schema/dataProcessingSchema";
import userCredentials from "@/schema/userCredentialsSchema";

export default {
  name: "CreateAccountOutboundForm",
  components: {
    TitleGroup,
    FormGroup,
    BasePasswordInput,
    LoginOrCreateAccountMessageLink,
    DataProcessingAgreementCheckbox,
    BaseLabel,
    BaseButton,
    BaseTextInput,
    BaseBadge,
    BaseForm,
  },
  data() {
    return {
      ...userCredentials,
      ...dataProcessingSchema,
      email: "labas@rimti.lt",
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      signIn();
      this.$router.push({ name: "confirm-email" });
    },
  },
};
</script>

<template>
  <div class="space-y-6">
    <div class="space-y-2">
      <BaseBadge variant="secondary">UAB Rimti</BaseBadge>
      <TitleGroup
        :title="$t('content.completeYourAccountFirst')"
        :subtitle="$t('content.yourCompanyWereInvited')"
      />
    </div>
    <BaseForm ref="form">
      <template #form-content>
        <FormGroup>
          <BaseLabel for="email">{{ $t("input.email.label") }}</BaseLabel>
          <BaseTextInput
            rules="email|required"
            id="email"
            :fieldName="$t('input.email.fieldName')"
            :placeholder="$t('input.email.placeholder')"
            v-model="email"
            :disabled="true"
          />
        </FormGroup>
        <FormGroup>
          <BaseLabel for="password">{{ $t("input.password.label") }}</BaseLabel>
          <BasePasswordInput
            id="password"
            :placeholder="$t('input.password.placeholderCreateAccount')"
            v-model="password"
          />
        </FormGroup>
        <DataProcessingAgreementCheckbox
          class="pt-2"
          v-model="dataProcessingAgreement"
        />
      </template>
    </BaseForm>

    <BaseButton
      :label="$t('button.createAccount')"
      class="p-button-sm w-full text-center"
      @click="handleSubmit"
    />

    <LoginOrCreateAccountMessageLink />
  </div>
</template>
