<script>
import BaseMultiselect from "@/components/ui/BaseMultiselect";

export default {
  name: "SaleCountriesMultiselect",
  extends: BaseMultiselect,
  components: {
    BaseMultiselect,
  },
  computed: {
    saleCountries() {
      return [
        { label: this.$t("saleCountries.lithuania"), value: "lithuania" },
        { label: this.$t("saleCountries.latvia"), value: "latvia" },
        {
          label: this.$t("saleCountries.estonia"),
          value: "estonia",
        },
        {
          label: this.$t("saleCountries.finland"),
          value: "finland",
        },
      ];
    },
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>

<template>
  <BaseMultiselect
    data-component="sale-countries-multiselect"
    v-bind="props"
    v-on="$listeners"
    :options="saleCountries"
    :placeholder="$t('input.saleCountries.placeholder')"
    optionLabel="label"
    optionValue="value"
  />
</template>
