<script>
import BaseForm from "@/components/ui/BaseForm.vue";
import BaseLabel from "@/components/ui/BaseLabel";
import BaseTextInput from "@/components/ui/BaseTextInput";
import CountryDropdown from "@/components/ui/CountryDropdown";
import FormGroup from "@/components/ui/FormGroup";
import PhoneNumberInput from "@/components/ui/PhoneNumberInput.vue";
import RadioButtonWithLabel from "@/components/ui/RadioButtonWithLabel";

export default {
  name: "CompanyDetailsForm",
  extends: BaseForm,
  components: {
    FormGroup,
    CountryDropdown,
    PhoneNumberInput,
    RadioButtonWithLabel,
    BaseLabel,
    BaseTextInput,
    BaseForm,
  },
  props: {
    showPhoneNumber: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ...this.$attrs,
    };
  },
};
</script>

<template>
  <BaseForm>
    <template #form-content>
      <FormGroup>
        <BaseLabel>{{ $t("input.companyType.label") }}</BaseLabel>
        <div class="grid grid-cols-2 gap-4">
          <RadioButtonWithLabel
            rules="required"
            :label="$t('input.companyType.legalPlaceholder')"
            :fieldName="$t('input.companyType.label')"
            name="companyType"
            value="legal"
            id="legal"
            v-model="formData.companyType"
          />
          <RadioButtonWithLabel
            :label="$t('input.companyType.individualPlaceholder')"
            name="companyType"
            value="individual"
            id="individual"
            v-model="formData.companyType"
          />
        </div>
      </FormGroup>

      <FormGroup v-if="formData.companyType == 'legal'" key="field_companyCode">
        <BaseLabel for="companyCode">{{
          $t("input.companyCode.label")
        }}</BaseLabel>
        <BaseTextInput
          rules="required"
          id="companyCode"
          :fieldName="$t('input.companyCode.fieldName')"
          :placeholder="$t('input.companyCode.placeholder')"
          v-model="formData.companyCode"
        />
      </FormGroup>

      <FormGroup
        v-if="formData.companyType == 'individual'"
        key="field_companyCertificateNumber"
      >
        <BaseLabel for="companyCertificateNumber">{{
          $t("input.individualEconomicActivity.label")
        }}</BaseLabel>
        <BaseTextInput
          rules="required"
          id="companyCertificateNumber"
          :fieldName="$t('input.individualEconomicActivity.fieldName')"
          :placeholder="$t('input.individualEconomicActivity.placeholder')"
          v-model="formData.companyCertificateNumber"
        />
      </FormGroup>

      <FormGroup>
        <BaseLabel for="companyName">{{
          $t("input.registeredCompanyName.label")
        }}</BaseLabel>
        <BaseTextInput
          rules="required"
          id="companyName"
          :fieldName="$t('input.registeredCompanyName.fieldName')"
          :placeholder="$t('input.registeredCompanyName.placeholder')"
          v-model="formData.companyName"
        />
        <p class="text-xs text-neutral-foreground">
          {{ $t("input.registeredCompanyName.description") }}
        </p>
      </FormGroup>

      <FormGroup>
        <BaseLabel for="registrationCountry">{{
          $t("input.countryOfRegistration.label")
        }}</BaseLabel>
        <CountryDropdown
          rules="required"
          :fieldName="$t('input.countryOfRegistration.fieldName')"
          inputId="registrationCountry"
          v-model="formData.registrationCountry"
        />
      </FormGroup>

      <FormGroup v-if="showPhoneNumber">
        <BaseLabel for="phoneNumber">{{
          $t("input.phoneNumber.label")
        }}</BaseLabel>
        <PhoneNumberInput
          rules="required|phone-number"
          v-model="formData.phoneNumber"
        />
      </FormGroup>
    </template>
  </BaseForm>
</template>
