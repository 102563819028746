import ConfirmEmailPage from "@/components/pages/ConfirmEmailPage.vue";
import CreateAccountOutboundPage from "@/components/pages/CreateAccountOutboundPage.vue";
import DocumentsSentPage from "@/components/pages/DocumentsSentPage.vue";
import HomePage from "@/components/pages/HomePage.vue";
import LoginPage from "@/components/pages/LoginPage.vue";
import OnboardingStepsPage from "@/components/pages/OnboardingStepsPage.vue";
import StartSellingPage from "@/components/pages/StartSellingPage.vue";
import onboardingSteps from "./onboarding-steps";

export default [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/create-account",
    name: "create-account",
    component: CreateAccountOutboundPage,
  },
  {
    path: "/flow",
    name: "flow",
    redirect: { name: "onboarding" },
  },
  {
    path: "/flow/confirm-email",
    name: "confirm-email",
    component: ConfirmEmailPage,
  },
  {
    path: "/flow/start-selling",
    component: StartSellingPage,
  },
  {
    path: "/flow/documents-sent",
    component: DocumentsSentPage,
  },
  {
    path: "/flow/onboarding",
    // Router view name
    name: "onboarding",
    redirect: "/flow/onboarding/step/1",
    component: OnboardingStepsPage,
    // Nested routes
    children: onboardingSteps,
  },
];
