<script>
import BaseSteps from "@/components/ui/BaseSteps.vue";
import {
  EVENT_ONBOARDING_COMPLETE,
  EVENT_ONBOARDING_NEXT_STEP,
  EVENT_ONBOARDING_PREV_STEP,
} from "@/lib/constants";
import { EventBus } from "@/lib/event-bus";

export default {
  name: "OnboardingStepper",
  components: {
    BaseSteps,
  },
  data() {
    return {
      model: [
        {
          name: "step-one",
          completed: false,
          isValid: false,
        },
        {
          name: "step-two",
          completed: false,
          isValid: false,
        },
        {
          name: "step-three",
          completed: false,
          isValid: false,
        },
        {
          name: "step-four",
          completed: false,
          isValid: false,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(function () {
      EventBus.$on(EVENT_ONBOARDING_NEXT_STEP, (event) => {
        if (!event) {
          return;
        }
        this.model = event.model;
      });
      EventBus.$on(EVENT_ONBOARDING_PREV_STEP, (event) => {
        if (!event) {
          return;
        }
        this.model = event.model;
      });
      EventBus.$on(EVENT_ONBOARDING_COMPLETE, (event) => {
        if (!event) {
          return;
        }
        this.model = event.model;
      });
    });
  },
  beforeDestroy() {
    EventBus.$off(EVENT_ONBOARDING_NEXT_STEP);
    EventBus.$off(EVENT_ONBOARDING_PREV_STEP);
    EventBus.$off(EVENT_ONBOARDING_COMPLETE);
  },
};
</script>

<template>
  <BaseSteps :model="model" />
</template>
