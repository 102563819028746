export default {
  en: {
    content: {
      alreadyHaveAnAccount: "Already have an account?",
      back: "Back",
      createAccount: "Create account",
      dontHaveAnAccount: "Don't have an account?",
      forgotPassword: "Forgot password?",
      logIn: "Log in",
      needHelp: "Need help?",
      next: "Next",
      privacyPolicy: "Privacy Policy",
      signOut: "Sign out",
      signUp: "Sign up",
      welcomeBackToMarketplace: "Welcome back to PHH Group Marketplace",
      createYourAccount: "Create your account",
      startSellingOnPhh: "Start selling on PHH Group Marketplace",
      completeYourAccountFirst: "Complete your account first",
      yourCompanyWereInvited:
        "Your company were invited to start selling on PHH Group Marketplace",
      shareSomeDetailsOfTheCompany:
        "Share some details of the company you represent",
      information: "Information",
      fillInYourPersonalAndCompanyInformation:
        "Fill in your personal and company information",
      fillInYourPersonalAndCompanyInformationSubtitle:
        "Nisi at elit sagittis ullamcorper felis proin pulvinar. Consectetur morbi ipsum elementum pulvinar arcu ullamcorper libero.",
      personalInformation: "Personal information",
      personalInformationSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      companyInformation: "Company information",
      companyInformationSubtitle:
        "Feugiat nibh nulla neque blandit blandit diam ullamcorper. Ornare justo cras dignissim aliquam egestas.",
      other: "Other",
      otherSubtitle:
        "Feugiat nibh nulla neque blandit blandit diam ullamcorper. Ornare justo cras dignissim aliquam egestas.",
      slogan:
        "Our sales platform - your sales channel in the Baltics and Finland",
      potentialBuyers: { number: "11.5 million", label: "Potential buyers" },
      activeBuyers: { number: "1.8 million", label: "Active buyers" },
      buyerVisitsEveryday: {
        number: "410 000+",
        label: "Buyer visits everyday",
      },
      ordersFulfilledPerYear: {
        number: "3 million",
        label: "Orders fulfilled per year",
      },
      agreementInformation: "Agreement information",
      fillInAgreementInformation: "Fill in agreement information",
      fillInAgreementInformationSubtitle:
        "Ullamcorper imperdiet curabitur malesuada dolor semper aliquet. Nibh vel urna elementum orci felis faucibus.",
      businessInformation: "Business information",
      businessInformationSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      clickToUpload: "Click to upload",
      orDragAndDrop: "or drag and drop",
      productsInformation: "Products information",
      productsInformationSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      contractSignee: "Contract signee",
      contractSigneeSubtitle:
        "Feugiat nibh nulla neque blandit blandit diam ullamcorper. Ornare justo cras dignissim aliquam egestas.",
      contactPersonForPerformanceAgreements:
        "Contact person for the performance of agreements",
      contactPersonForPerformanceAgreementsSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      resendEmail: "Resend email",
      contactEmailAddress: "xxx@pigu.lt",
      contactUs: "Contact us",
      signAgreements: "Sign agreements",
      downloadAndSignAgreements: "Download and sign agreements",
      downloadAndSignAgreementsSubtitle:
        "Nunc turpis nunc tincidunt vulputate et scelerisque eros ac justo. Arcu nunc parturient scelerisque ullamcorper sagittis.",
      downloadAgreements: "Download agreements",
      downloadAgreementsSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      sellerAgreement: "Seller Agreement",
      commercialAgencyAgreement: "Commercial Agency Agreement",
      dataProcessingAgreement: "Data Processing Agreement",
      uploaded: "Uploaded {date}",
      printAndSignAgreements: "Print and sign agreements",
      printAndSignAgreementsSubtitle:
        "Nibh urna eget risus nec dolor ultricies id. Eu urna non pharetra dui vestibulum. Et vitae sed nulla enim. Urna non porttitor in vivamus turpis ullamcorper accumsan dignissim. Fermentum mauris porta a elementum vestibulum eu.",
      uploadSignedAgreements: "Upload signed agreements",
      uploadSignedAgreementsSubtitle:
        "Ipsum non ipsum odio sit egestas. Ultrices vehicula sed condimentum natoque id vitae.",
      optional: "Optional",
      select: "Select",
      help: "Help",
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthNamesShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    informationCard: {
      didntReceiveAnEmail: {
        title: "Didn't receive an email?",
        content:
          "If you do not see the email in your inbox, check your Spam folder or report the issue {contactEmailAddress}.",
      },
      verifyYourEmailAddress: {
        title: "First, verify your email address",
        content:
          "To start selling on PHH platform, please open the email we sent to {email} and click the {action} button. After successfully confirming your email, you will be able to complete your account creation process.",
        confirmEmailAddressAction: "Confirm email address",
      },
      emailVerificationLinkExpired: {
        title: "The email verification link is invalid or has expired.",
        content:
          "The email verification link you clicked on is either invalid or has expired.",
        description:
          'Please click "Resend email" to receive a new verification to {email} email.',
      },
      submissionReceived: {
        title: "Your submission has been received successfully",
        content:
          "Our team will review the information within {timeframe} and upload the contract for signing.",
        timeframe: "1 working day",
        notificationTitle: "We are preparing your agreement documents",
        notificationDescription:
          "We will notify you when the documents will be ready. You can close this window.",
        alertTitle: "Didn't receive your agreement documents?",
        alertDescription:
          "If you don't receive an email with notification and files do not appear here, please {contactUs} or report the issue {contactEmailAddress}.",
      },
      documentsSuccessfullySent: {
        title: "Your documents have been successfully sent",
        content:
          "Our team will review the agreements and approve your seller's account within {timeframe}.",
        timeframe: "1 working day",
        notificationTitle: "We are reviewing your files and documents",
        notificationDescription:
          "We will notify you when your documents will be reviewed. You can close this window.",
        alertTitle: "Account not approved?",
        alertDescription:
          "If your account is not approved after 1 working day, please {contactUs} or report the issue {contactEmailAddress}.",
      },
      startSelling: {
        title: "You can start selling with PHH platform!",
        content:
          "Adipiscing lectus rhoncus proin viverra diam. Justo quam in orci eleifend mauris pharetra sit parturient.",
      },
    },
    alertCard: {
      emailVerificationLinkExpired: {
        title:
          "The email verification link you clicked on is either invalid or has expired.",
        content:
          'To proceed, please log in to your account and click "Resend Email" to get a new one.',
      },
    },
    input: {
      email: {
        label: "Email",
        placeholder: "Enter your email",
        fieldName: "Email",
      },
      password: {
        label: "Password",
        placeholder: "Enter password",
        placeholderCreateAccount: "Set your password",
        fieldName: "Password",
      },
      companyType: {
        label: "Company type",
        legalPlaceholder: "Legal person",
        individualPlaceholder: "Individual",
        legalFieldName: "Company type legal",
        individualFieldName: "Company type individual",
      },
      companyCode: {
        label: "Company code",
        placeholder: "Enter company code",
        fieldName: "Company code",
      },
      individualEconomicActivity: {
        label:
          "Individual Economic Activity Certificate number/Copyright agreement number",
        placeholder: "Enter number",
        fieldName: "Individual economic activity certificate",
      },
      registeredCompanyName: {
        label: "Registered company name",
        placeholder: "Enter your company's name",
        description: "Correct format: Acme, Inc.",
        fieldName: "Registered company name",
      },
      countryOfRegistration: {
        label: "Country of registration",
        placeholder: "Select country",
        filterPlaceholder: "Search country",
        fieldName: "Country of registration",
      },
      phoneNumber: {
        label: "Phone number",
        placeholder: "612 34567",
        dropdownPlaceholder: "Search",
        filterPlaceholder: "Search country or prefix",
        fieldName: "Phone number",
      },
      contactUsMessage: {
        label: "Message",
        placeholder: "Enter your message here...",
        fieldName: "Message",
      },
      dataProcessingAgreement: {
        label: "Agree to the processing of data provided and {privacyPolicy}.",
        fieldName: "Data processing agreement",
      },
      fullName: {
        label: "Full name",
        placeholder: "Enter your full name",
        fieldName: "Full name",
      },
      websiteLink: {
        label: "Website link",
        placeholder: "https://",
        fieldName: "Website link",
      },
      howDidYouDiscoverPhh: {
        label: "How did you discover the possibility of trading on PHH?",
        placeholder: "@:content.select",
        fieldName: "",
      },
      vatNumber: {
        label: "VAT number",
        placeholder: "XX123456789",
        fieldName: "VAT number",
      },
      personalCode: {
        label: "Personal code",
        placeholder: "Enter personal code",
        fieldName: "Personal code",
      },
      dateOfBirth: {
        label: "Date of birth",
        placeholder: "YYYY-MM-DD",
        fieldName: "Date of birth",
      },
      copyOfIndividualEconomicActivity: {
        label: "Copy of Individual Economic Activity",
        placeholder: "Click to upload or drag and drop",
        fieldName: "Copy of individual economic activity",
      },
      signedAgreements: {
        fieldName: "Signed agreements",
      },
      addressOfRegistration: {
        label: "Address of registration",
        placeholder: "Enter full registration address",
        fieldName: "Address of registration",
      },
      bankAccountNumber: {
        label: "Bank account number",
        placeholder: "XX123456789",
        fieldName: "Bank account number",
      },
      bankName: {
        label: "Bank name",
        placeholder: "Provide your bank name",
        fieldName: "Bank name",
      },
      productCategories: {
        label: "Product categories",
        placeholder: "@:content.select",
        fieldName: "Product categories",
      },
      numberOfSKUs: {
        label: "Number of SKUs",
        placeholder: "",
        fieldName: "Number of SKUs",
      },
      shippingMethod: {
        label: "Shipping method",
        placeholder: "@:content.select",
        fieldName: "Shipping method",
      },
      deliveryAddress: {
        label: "Select delivery address",
        placeholder: "@:content.select",
        fieldName: "Delivery address",
      },
      representativesDuties: {
        label: "Representative's duties",
        placeholder: "Enter representative's duties",
        fieldName: "Representative's duties",
      },
      contractSignee: {
        label: "Full name",
        placeholder: "Enter contract signee full name",
        fieldName: "Contract signee full name",
      },
      contactPerson: {
        label: "Full name",
        placeholder: "Enter contact person full name",
        fieldName: "Contract person full name",
      },
      contactPersonDuties: {
        label: "Contact Person's duties",
        placeholder: "Enter contact person's duties",
        fieldName: "Contact person's duties",
      },
      saleCountries: {
        label: "Sale countries",
        placeholder: "Select",
        fieldName: "Sale countries",
      },
    },
    shippingMethods: {
      crossDocking: "Cross-docking",
      directDelivery: "Direct delivery",
    },
    productCategories: {
      mobilePhones: "Mobile phones",
      tv: "TV",
      computers: "Computers",
      headphones: "Headphones",
      accessories: "Accessories",
    },
    deliveryAddresses: {
      lithuania: "Lithuania (Sausupio g. 15A, Vilnius)",
      latvia: "Latvia (Krasta iela 52, Rīga)",
      estonia: "Estonia (Omniva, Pallasti 28, Tallinn)",
      finland: "Finland (Osumakuja 1-3 Vantaa)",
      poland: "Poland (Marii Świątkiewicz 51/D29 hala EUROPEAN, Mroków)",
    },
    discoveryOptions: {
      television: "Television",
      radio: "Radio",
      facebook: "Facebook",
      instagram: "Instagram",
      press: "Press",
      friends: "Friends",
      events: "Events",
      onlineEvents: "Online events",
      elsewhere: "From elsewhere",
    },
    saleCountries: {
      lithuania: "Lithuania",
      latvia: "Latvia",
      estonia: "Estonia",
      finland: "Finland",
    },
    button: {
      resendEmail: "@:content.resendEmail",
      continue: "Continue",
      confirmInformation: "Confirm information",
      sendAgreements: "Send agreements",
      startSelling: "Start selling",
      download: "Download",
      sendMessage: "Send message",
      logIn: "@:content.logIn",
      signOut: "@:content.signOut",
      next: "@:content.next",
      back: "@:content.back",
      createAccount: "@:content.createAccount",
      needHelp: "@:content.needHelp",
      clear: "Clear",
    },
    toast: {
      fileUpload: {
        filesSuccesfullyAdded: "Your file was successfully added!",
        fileAlreadyExists: "File with this name already exists!",
        fileIsTooLarge:
          "Your file is too large. The maximum file size is {fileSize}.",
        fileFormatInvalid: "Your file format is not valid.",
        fileLimitReached:
          "You cannot add more files. The maximum number of files you can upload is {fileLimit}.",
      },
      contactUs: {
        contactUsSuccess: "Thank you for contacting us!",
      },
    },
    sidebar: {
      productCategoryHelp: {
        primaryTitle: "Nulla consectetur tincidunt platea",
        primaryContent:
          " Egestas id elit volutpat neque nunc sit. Suscipit massa elementum in condimentum varius.",
        secondaryTitle:
          "Urna adipiscing maecenas sem at dolor. Elementum tortor non at facilisis fusce.",
        secondaryContent:
          "Aliquam sodales gravida pretium pulvinar eleifend ultricies nam massa nibh. Ridiculus sit malesuada nunc nunc pulvinar. Mattis suspendisse vulputate risus egestas. Aenean fames duis at nulla aliquam scelerisque sed massa. Purus etiam aliquam iaculis risus at dui. Eros molestie sed lacinia volutpat sagittis dictumst mi. Volutpat ipsum pulvinar augue amet pulvinar pharetra tincidunt nisi quis. Scelerisque neque maecenas malesuada condimentum faucibus facilisis ipsum aliquet erat.",
      },
    },
    validation: {
      atLeastCharactersLong: "At least {characters} characters long",
      containsAtLeastOneNumber: "Contains at least one number",
      containsUpperAndLowerLetters: "Contains both upper and lower letters",
      invalidPhoneNumber: "Phone number is not valid",
      invalidDate: "Date is not valid",
      // Vee validate
      // https://github.com/logaretm/vee-validate/blob/main/packages/i18n/src/locale/en.json
      _default: "{_field_} field is not valid",
      email: "{_field_} must be a valid email",
      required: "{_field_} is required",
      regex: "{_field_} field is not valid",
    },
  },
};
