<script>
import DocumentsSentView from "./DocumentsSentView.vue";
import StartSellingView from "./StartSellingView.vue";

export default {
  name: "DocumentsSentWrapperView",
  components: {
    DocumentsSentView,
    StartSellingView,
  },
  data() {
    return {
      // onboaridng/step/<...>?canStartSelling=true
      canStartSelling: this.$route.query?.canStartSelling
        ? this.$route.query?.canStartSelling === "true"
        : false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.canStartSelling) {
        this.$router.push({ name: "start-selling" });
      }
    });
  },
};
</script>

<template>
  <DocumentsSentView v-if="!canStartSelling" v-on="$listeners" />
  <StartSellingView v-else v-on="$listeners" />
</template>
