<script>
import CreateAccountOutboundForm from "@/components/forms/CreateAccountOutboundForm";

export default {
  name: "CreateAccountOutboundPage",
  components: { CreateAccountOutboundForm },
};
</script>

<template>
  <CreateAccountOutboundForm />
</template>
