<script>
import CompanyDetailsFormView from "@/components/forms/create-account-inbound/CompanyDetailsFormView";
import UserCredentialsFormView from "@/components/forms/create-account-inbound/UserCredentialsFormView";
import companyDetailsSchema from "@/schema/companyDetailsSchema";
import dataProcessingSchema from "@/schema/dataProcessingSchema";
import userCredentialsSchema from "@/schema/userCredentialsSchema";

export default {
  name: "CreateAccountInboundFormView",
  components: {
    CompanyDetailsFormView,
    UserCredentialsFormView,
  },
  data() {
    return {
      step: 1,
      formData: {
        ...companyDetailsSchema,
        ...userCredentialsSchema,
        ...dataProcessingSchema,
      },
    };
  },
  methods: {
    renderCompanyDetailsForm() {
      this.step = 1;
    },
    renderUserCredentialsForm() {
      this.step = 2;
    },
  },
};
</script>

<template>
  <div>
    <CompanyDetailsFormView
      v-if="step === 1"
      :nextStep="renderUserCredentialsForm"
      :formData="formData"
    />
    <UserCredentialsFormView
      v-if="step === 2"
      :prevStep="renderCompanyDetailsForm"
      :formData="formData"
    />
  </div>
</template>
