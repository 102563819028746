<script>
import FormMessage from "@/components/ui/FormMessage";
import PrimeTextarea from "primevue/textarea";
import { ValidationProvider } from "vee-validate";

export default {
  name: "BaseTextarea",
  extends: PrimeTextarea,
  components: {
    PrimeTextarea,
    FormMessage,
    ValidationProvider,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  computed: {
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    tag="div"
    class="!overflow-visible"
  >
    <PrimeTextarea
      v-bind="parentProps"
      v-on="$listeners"
      class="input w-full"
    />
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>

<style scoped>
.p-inputtextarea-resizable {
  overflow: auto !important;
}
</style>
