<script>
export default {
  name: "BaseAvatar",
};
</script>

<template>
  <div
    class="flex h-12 w-full max-w-12 items-center justify-center rounded-full bg-primary p-2 md:h-16 md:max-w-16"
  >
    <slot></slot>
  </div>
</template>
