<script>
import NotificationCard from "@/components/ui/NotificationCard.vue";
import BaseCard from "@/components/ui/BaseCard.vue";
import ContactUsEmailAddress from "@/components/ui/ContactUsEmailAddress.vue";
import ContactUsLink from "@/components/ui/ContactUsLink.vue";

export default {
  name: "SubmissionReceivedView",
  components: {
    NotificationCard,
    BaseCard,
    ContactUsEmailAddress,
    ContactUsLink,
  },
};
</script>

<template>
  <NotificationCard icon="check">
    <template #title>{{
      $t("informationCard.submissionReceived.title")
    }}</template>
    <template #description>
      <BaseCard
        intent="info"
        variant="alert"
        alertIcon="clock-fill"
        alertIconClassName="[&_path]:fill-primary"
        padding="md"
        border="none"
        alert="default"
      >
        <template #alert-label>{{
          $t("informationCard.submissionReceived.notificationTitle")
        }}</template>
        <template #alert-content>
          <p>
            {{
              $t("informationCard.submissionReceived.notificationDescription")
            }}
          </p>
        </template>
      </BaseCard>
      <i18n path="informationCard.submissionReceived.content" tag="p">
        <template #timeframe>
          <span class="font-semibold">{{
            $t("informationCard.submissionReceived.timeframe")
          }}</span>
        </template>
      </i18n>
    </template>
    <template #footer>
      <BaseCard
        intent="default"
        variant="alert"
        alertIcon="question-fill"
        :alertIconSmall="true"
        padding="md"
      >
        <template #alert-label>{{
          $t("informationCard.submissionReceived.alertTitle")
        }}</template>
        <template #alert-content-alternate>
          <i18n
            path="informationCard.submissionReceived.alertDescription"
            tag="p"
          >
            <template #contactUs>
              <ContactUsLink />
            </template>
            <template #contactEmailAddress>
              <ContactUsEmailAddress />
            </template>
          </i18n>
        </template>
      </BaseCard>
    </template>
  </NotificationCard>
</template>
