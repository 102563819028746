<script>
import CompanyDetailsInboundForm from "../forms/CompanyDetailsInboundForm.vue";
import CompanyDetailsOutboundForm from "../forms/CompanyDetailsOutboundForm.vue";
import { isInbound } from "@/lib/utils";

export default {
  name: "CompanyDetailsView",
  components: {
    CompanyDetailsInboundForm,
    CompanyDetailsOutboundForm,
  },
  data() {
    return {
      isInbound: isInbound(this),
    };
  },
};
</script>

<template>
  <CompanyDetailsInboundForm v-if="isInbound" v-on="$listeners" />
  <CompanyDetailsOutboundForm v-else v-on="$listeners" />
</template>
