<script>
import PrimeDialog from "primevue/dialog";

export default {
  name: "BaseDialog",
  extends: PrimeDialog,
  components: {
    PrimeDialog,
  },
};
</script>
