<script>
import BaseDropdown from "@/components/ui/BaseDropdown";
import BaseIcon from "@/components/ui/BaseIcon";
import { EVENT_LANGUAGE_CHANGE } from "@/lib/constants";
import { EventBus } from "@/lib/event-bus";
import { getLanguage } from "@/lib/utils";
import { loadLanguageAsync } from "@/setup/i18n-setup";

export default {
  name: "LanguageSelect",
  extends: BaseDropdown,
  components: { BaseDropdown, BaseIcon },
  data() {
    return {
      language: getLanguage() || "en",
      languages: [
        { label: "LT", value: "lt" },
        { label: "EN", value: "en" },
        { label: "EE", value: "ee" },
        { label: "LV", value: "lv" },
        { label: "RU", value: "ru" },
        { label: "PL", value: "pl" },
      ],
    };
  },
  methods: {
    getSelectedOption(value) {
      return this.languages.find((item) => item.value === value);
    },
    onChange(e) {
      if (!e || !e.value) {
        return;
      }
      loadLanguageAsync(e.value);
      EventBus.$emit(EVENT_LANGUAGE_CHANGE, e.value);
    },
  },
};
</script>

<template>
  <BaseDropdown
    data-component="language-select"
    v-model="language"
    :options="languages"
    optionLabel="label"
    optionValue="value"
    scrollHeight="300px"
    @change="onChange"
  >
    <template #value="slotProps">
      <div class="flex items-center gap-2">
        <BaseIcon
          icon="globe"
          class="hidden translate-x-[2px] md:block [&>svg]:h-5 [&>svg]:w-5 [&>svg]:max-w-5"
        />
        <div class="text-text-color text-sm font-semibold">
          {{ getSelectedOption(slotProps.value).label }}
        </div>
      </div>
    </template>
  </BaseDropdown>
</template>
