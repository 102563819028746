<script>
import HeroSection from "@/components/sections/HeroSection";
import BaseHeader from "@/components/ui/BaseHeader";
import BaseFooter from "@/components/ui/BaseFooter";
import BaseToast from "@/components/ui/BaseToast";

export default {
  name: "BaseLayout",
  components: {
    HeroSection,
    BaseHeader,
    BaseFooter,
    BaseToast,
  },
};
</script>

<template>
  <div class="flex min-h-[100dvh] flex-col">
    <BaseHeader />
    <div class="grid grid-cols-1 gap-4 md:min-h-screen md:grid-cols-2 md:pb-0">
      <div
        class="mx-auto w-full max-w-content p-4 pt-12 md:self-center md:pt-4"
      >
        <slot></slot>
      </div>
      <div
        class="relative hidden items-center justify-center bg-primary p-4 md:flex"
      >
        <HeroSection />
      </div>
    </div>
    <BaseFooter class="mt-auto" />
    <BaseToast position="bottom-right" />
  </div>
</template>
