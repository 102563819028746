<script>
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import BaseIcon from "./BaseIcon.vue";

export default {
  name: "BaseCard",
  props: {
    variant: {
      type: String,
      default: "light",
    },
    intent: {
      type: String,
    },
    padding: {
      type: String,
      default: "default",
    },
    alert: {
      type: String,
    },
    border: {
      type: String,
    },
    shadow: {
      type: String,
    },
    alertIcon: {
      type: String,
    },
    alertIconClassName: {
      type: String,
    },
    alertIconSmall: {
      type: Boolean,
    },
    footerClassName: {
      type: String,
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    footerClassNameComputed() {
      return cn("flex gap-4 bg-neutral border-t p-4", this.footerClassName);
    },
    className() {
      const padding = {
        default: this.$slots.footer
          ? "[&_[data-card-content]]:md:p-6 [&_[data-card-content]]:p-4"
          : "p-4 md:p-6",
        md: this.$slots.footer ? "[&_[data-card-content]]:p-4" : "p-4",
        sm: this.$slots.footer ? "[&_[data-card-content]]:p-2" : "p-2",
        none: "",
      };

      const variants = cva("border rounded-xl", {
        variants: {
          intent: {
            default: "bg-base-color-secondary border",
            info: "bg-primary-light",
            success:
              "text-text-primary bg-success-color-lighter border border-success-color-light",
            warning:
              "text-text-primary bg-yellow-color-lighter border border-yellow-color-additional",
            error: "bg-red-color text-white [&_img]:invert",
          },
          variant: {
            alert: "text-sm",
            transparent: "bg-transparent",
            light: "bg-white",
            dark: "bg-primary-hover text-white",
            none: "",
          },
          alert: {
            default:
              "[&_[data-alert-label]]:text-base [&_[data-alert-content]]:text-sm [&_[data-alert-footer]]:text-sm",
            sm: "[&_[data-alert-label]]:text-sm [&_[data-alert-content]]:text-sm [&_[data-alert-footer]]:text-sm",
          },
          padding,
          border: {
            none: "border-none",
          },
          shadow: {
            true: "shadow",
          },
        },
      });
      return cn(
        variants({
          intent: this.$props.intent,
          variant: this.$props.variant,
          alert: this.$props.alert,
          padding: this.$props.padding,
          border: this.$props.border,
          shadow: this.$props.shadow,
        }),
        this.$attrs.className
      );
    },
    alertIconClass() {
      return cn(
        "flex-shrink-0 flex-grow translate-y-[-1px]",
        this.alertIconClassName,
        this.alertIconSmall
          ? "max-w-5 h-5 [&>svg]:w-5 [&>svg]:h-5"
          : "max-w-6 h-6 [&>svg]:w-6 [&>svg]:h-6"
      );
    },
  },
};
</script>

<template>
  <div :class="className" v-on="$listeners">
    <div class="space-y-2" v-if="variant === 'alert'">
      <div
        class="flex"
        :class="alertIconSmall ? 'gap-2' : 'gap-4'"
        v-if="$slots['alert-label']"
      >
        <BaseIcon v-if="alertIcon" :class="alertIconClass" :icon="alertIcon" />
        <div class="space-y-1">
          <div v-if="$slots['alert-label']" data-alert-label>
            <slot name="alert-label"></slot>
          </div>
          <div
            v-if="$slots['alert-content']"
            class="text-neutral-foreground"
            data-alert-content
          >
            <slot name="alert-content"></slot>
          </div>
          <div v-if="$slots['alert-footer']" data-alert-footer>
            <slot name="alert-footer"></slot>
          </div>
        </div>
      </div>
      <div
        v-if="$slots['alert-content-alternate']"
        class="text-neutral-foreground"
      >
        <slot name="alert-content-alternate"></slot>
      </div>
      <div v-if="$slots['alert-footer-alternate']">
        <slot name="alert-footer-alternate"></slot>
      </div>
    </div>
    <slot></slot>
    <div v-if="$slots.content" data-card-content>
      <slot name="content"></slot>
    </div>
    <div v-if="$slots.footer" :class="footerClassNameComputed" data-card-footer>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
