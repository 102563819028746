<script>
import BaseCheckbox from "@/components/ui/BaseCheckbox";
import BaseLabel from "@/components/ui/BaseLabel";
import { ValidationProvider } from "vee-validate";
import FormMessage from "@/components/ui/FormMessage";

export default {
  name: "DataProcessingAgreementCheckbox",
  extends: BaseCheckbox,
  components: {
    BaseCheckbox,
    BaseLabel,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
    validationProviderProps() {
      return {
        rules: this.$attrs.rules,
        name: this.$props.fieldName,
      };
    },
  },
};
</script>

<template>
  <ValidationProvider
    class="space-y-1"
    tag="div"
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    :rules="{ required: { allowFalse: false } }"
    :name="$t('input.dataProcessingAgreement.fieldName')"
  >
    <div class="flex items-center gap-2 space-y-0">
      <BaseCheckbox
        :binary="true"
        v-bind="props"
        v-on="$listeners"
        id="dataProcessingAgreement"
        name="dataProcessingAgreement"
      />
      <BaseLabel for="dataProcessingAgreement">
        <i18n path="input.dataProcessingAgreement.label">
          <template #privacyPolicy>
            <a href="#">{{ $t("content.privacyPolicy") }}</a>
          </template>
        </i18n>
      </BaseLabel>
    </div>
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>
