<script>
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import BaseIcon from "@/components/ui/BaseIcon";

export default {
  name: "BaseBadge",
  components: {
    BaseIcon,
  },
  props: {
    intent: {
      type: String,
      default: "default",
    },
    variant: {
      type: String,
      default: "default", // | 'secondary'
    },
    iconLeft: {
      type: String,
    },
    iconRight: {
      type: String,
    },
  },
  computed: {
    className() {
      const variants = cva(
        "text-xs rounded-xl px-2 py-[2px] font-semibold w-fit flex items-center gap-1 font-geist h-5",
        {
          variants: {
            intent: {
              default: "bg-primary text-white [&_img]:invert",
              success: "bg-success text-white [&_img]:invert",
              info: "bg-neutral-secondary",
              warning: "bg-yellow-color",
              error: "bg-red-color text-white [&_img]:invert",
              accent: "bg-purple-color text-white [&_img]:invert",
            },
          },
          compoundVariants: [
            {
              intent: "default",
              variant: "secondary",
              class: "bg-primary-light border border-primary text-primary",
            },
            {
              intent: "error",
              variant: "secondary",
              class:
                "bg-error-color-light border border-error-color text-danger",
            },
            {
              intent: "info",
              variant: "secondary",
              class:
                "bg-neutral border border-neutral-hover text-neutral-foreground",
            },
            {
              intent: "success",
              variant: "secondary",
              class:
                "bg-success-color-lighter border border-success-color-light text-success",
            },
          ],
        }
      );
      return cn(
        variants({ intent: this.intent, variant: this.variant }),
        this.$attrs.className
      );
    },
  },
};
</script>
<template>
  <span :class="className">
    <span
      v-if="iconLeft"
      class="h-4 w-4 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:max-w-4"
    >
      <BaseIcon :icon="iconLeft" />
    </span>
    <span>
      <slot></slot>
    </span>
    <span
      v-if="iconRight"
      class="h-4 w-4 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:max-w-4"
    >
      <BaseIcon :icon="iconRight" />
    </span>
  </span>
</template>
