<script>
import BaseIcon from "@/components/ui/BaseIcon.vue";
import FormMessage from "@/components/ui/FormMessage";
import PrimeMultiselect from "primevue/multiselect";
import {
  applyReverseTranslateYToDropdownPanel,
  handleDropdownPanelSizeChanges,
} from "@/lib/utils";
import { ValidationProvider } from "vee-validate";

export default {
  name: "BaseMultiselect",
  extends: PrimeMultiselect,
  components: {
    PrimeMultiselect,
    BaseIcon,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
  mounted() {
    this.$nextTick(function () {
      // PrimeVue multiselect doesn't come with a clear button. So we add it on mount.
      const parent = this.$refs.multiselect?.$el;
      const trigger = parent?.querySelector(".p-multiselect-trigger");
      const clearIcon = this.$refs.clearIcon;
      parent?.insertBefore(clearIcon, trigger);
      if (this.$props.value) {
        clearIcon.classList.remove("hidden");
      }
    });
  },
  methods: {
    getLabelByValue() {
      return this.$props.value?.map(
        (value) =>
          this.$props.options?.find((item) => item.value === value).label
      );
    },
    clearOptions(e) {
      e.stopPropagation();
      this.$emit("input", []);
      this.$emit("change", { originalEvent: e, value: [] });
      this.$refs.clearIcon.classList.add("hidden");
    },
    removeOption(e, index) {
      e.preventDefault();
      const newValues = this.$props.value.toSpliced(index, 1);
      this.$emit("input", newValues);
      this.$emit("change", { originalEvent: e, value: newValues });

      if (newValues.length === 0) {
        this.$refs.clearIcon.classList.add("hidden");
      }
    },
    onChange(e) {
      const value = e.value;
      const clearIcon = this.$refs.clearIcon;

      if (value) {
        clearIcon.classList.remove("hidden");
      }

      if (value.length === 0) {
        clearIcon.classList.add("hidden");
      }
    },
    applyReverseTranslateY() {
      const panel = this.$refs.multiselect.$el.querySelector(
        ".p-multiselect-panel "
      );
      handleDropdownPanelSizeChanges(
        panel,
        applyReverseTranslateYToDropdownPanel
      );
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    class="space-y-1"
    tag="div"
  >
    <button
      ref="clearIcon"
      @click.prevent="clearOptions"
      class="clear-icon mt-2 hidden"
    >
      <i class="pi pi-times"></i>
    </button>
    <PrimeMultiselect
      ref="multiselect"
      v-bind="props"
      v-on="$listeners"
      @change="onChange"
      @show="applyReverseTranslateY"
      :filter="true"
      :showToggleAll="false"
      class="input w-full"
    >
      <template #value="slotProps">
        <div
          v-for="(item, index) of getLabelByValue()"
          :key="`multiselect_label_${index}`"
        >
          <div
            class="flex w-fit items-center gap-1 rounded-xl bg-primary-light px-2 py-[2px] text-sm"
          >
            <span>
              {{ item }}
            </span>
            <button @click.stop="(e) => removeOption(e, index)">
              <BaseIcon icon="x" class="h-5 w-5 [&>svg]:h-5 [&>svg]:w-5" />
            </button>
          </div>
        </div>
        <template v-if="!slotProps.value || slotProps.value.length === 0">
          {{ $t("content.select") }}
        </template>
      </template>
    </PrimeMultiselect>
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>
