<script>
import BaseFooter from "@/components/ui/BaseFooter";
import BaseHeader from "@/components/ui/BaseHeader";
import BaseToast from "@/components/ui/BaseToast";
import OnboardingStepper from "@/components/ui/OnboardingStepper";
import { isOnboarding } from "@/lib/utils";

export default {
  name: "InternalFlowLayout",
  components: {
    BaseHeader,
    BaseFooter,
    OnboardingStepper,
    BaseToast,
  },
  data() {
    return {
      isOnboarding: isOnboarding(this),
    };
  },
};
</script>

<template>
  <div class="min-h-screen bg-base-color-secondary">
    <BaseHeader />
    <div
      class="flex min-h-[calc(100vh-65px)] flex-col p-4 pt-6 md:p-8 md:pb-6 md:pt-10"
    >
      <div class="relative mx-auto w-full flex-1 self-center md:max-w-[498px]">
        <div v-if="isOnboarding" class="mb-6 block md:hidden">
          <OnboardingStepper />
        </div>
        <slot></slot>
      </div>
      <BaseFooter className="p-0 mt-8 md:mt-0 md:p-2 md:pb-0 md:static" />
    </div>
    <BaseToast position="bottom-right" />
  </div>
</template>
