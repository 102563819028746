<script>
import BaseCard from "@/components/ui/BaseCard";
import NotificationCard from "@/components/ui/NotificationCard.vue";
import ContactUsEmailAddress from "@/components/ui/ContactUsEmailAddress.vue";

export default {
  name: "ConfirmEmailView",
  components: {
    BaseCard,
    NotificationCard,
    ContactUsEmailAddress,
  },
};
</script>
<template>
  <div>
    <NotificationCard icon="envelope-simple">
      <template #title>{{
        $t("informationCard.verifyYourEmailAddress.title")
      }}</template>
      <template #description>
        <i18n path="informationCard.verifyYourEmailAddress.content" tag="p">
          <template #email>
            <span class="font-semibold">labas@rimti.lt</span>
          </template>
          <template #action>
            <span class="font-semibold">{{
              $t(
                "informationCard.verifyYourEmailAddress.confirmEmailAddressAction"
              )
            }}</span>
          </template>
        </i18n>
      </template>
      <template #footer>
        <BaseCard
          intent="default"
          variant="alert"
          alertIcon="question-fill"
          :alertIconSmall="true"
          padding="md"
        >
          <template #alert-label>{{
            $t("informationCard.didntReceiveAnEmail.title")
          }}</template>
          <template #alert-content-alternate>
            <i18n path="informationCard.didntReceiveAnEmail.content" tag="p">
              <template #contactEmailAddress>
                <ContactUsEmailAddress />
              </template>
            </i18n>
          </template>
          <template #alert-footer-alternate>
            <a href="#" class="block font-semibold">{{
              $t("content.resendEmail")
            }}</a>
          </template>
        </BaseCard>
      </template>
    </NotificationCard>
  </div>
</template>
