<script>
import BaseDropdown from "@/components/ui/BaseDropdown";
import CountryFlag from "vue-country-flag";
import countries from "@/lib/countries";

export default {
  name: "CountryDropdown",
  extends: BaseDropdown,
  components: { BaseDropdown, CountryFlag },
  props: {
    fieldName: {
      type: String,
    },
  },
  data() {
    return {
      countries,
    };
  },
  computed: {
    parentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
};
</script>

<template>
  <BaseDropdown
    data-component="country-dropdown"
    optionLabel="name"
    optionValue="code"
    :placeholder="$t('input.countryOfRegistration.placeholder')"
    :filterPlaceholder="$t('input.countryOfRegistration.filterPlaceholder')"
    v-on="$listeners"
    v-bind="parentProps"
    :options="countries"
    :filter="true"
    :showClear="true"
  >
    <template #option="slotProps">
      <div class="flex items-center gap-2">
        <div class="h-5 w-5 overflow-hidden rounded-lg">
          <CountryFlag :country="slotProps.option.code" size="normal" />
        </div>
        <div class="text-sm">{{ slotProps.option.name }}</div>
      </div>
    </template>
  </BaseDropdown>
</template>

<style scoped>
:deep(.normal-flag.flag) {
  transform: scale(0.6);
  margin: -16px 0px -11px -16px;
}
</style>
