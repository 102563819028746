<script>
import BaseCard from "@/components/ui/BaseCard.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import BaseFileCard from "@/components/ui/BaseFileCard.vue";
import prettyBytes from "pretty-bytes";
import BaseButton from "@/components/ui/BaseButton.vue";
import FormMessage from "@/components/ui/FormMessage.vue";
import { TOAST_LIFE } from "@/lib/constants";
import { ValidationProvider } from "vee-validate";
import { fileTypes } from "@/lib/file-upload";

export default {
  name: "BaseFileUpload",
  components: {
    BaseCard,
    BaseIcon,
    BaseFileCard,
    BaseButton,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    fileLimit: {
      type: Number,
      default: null,
    },
    accept: {
      /**
       * @prop {keyof file-upload.js} type takes in the keys of file-upload.js as an argument. Comma separated.
       * @example accept="pdf,doc,png"
       * @example accept="pdf"
       */
      type: String,
      default: null,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    value() {
      if (this.files.length === 0) {
        return "";
      }
      return this.files.length;
    },
    props() {
      return { ...this.$attrs, ...this.$props };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
    formattedMaxFileSize() {
      if (!this.maxFileSize) {
        return 0;
      }

      return prettyBytes(this.maxFileSize);
    },
    acceptedFormats() {
      return this.accept
        ?.split(",")
        .map((item) => {
          item.trim();
          return item.toUpperCase();
        })
        .join(", ");
    },
    acceptedTypes() {
      return this.accept
        .split(",")
        .map((item) => {
          item.trim();
          return fileTypes[item.toLowerCase()]
            .split(",")
            .map((item) => item.trim());
        })
        .flat();
    },
  },
  methods: {
    onDragOver() {
      this.$refs.card.$el.classList.add("border-primary");
    },
    onDragLeave() {
      this.$refs.card.$el.classList.remove("border-primary");
    },
    onDrop(e) {
      const dataTransfer = e.dataTransfer;
      this.$refs.card.$el.classList.remove("border-primary");

      if (!dataTransfer) {
        return;
      }

      this.pushFiles(dataTransfer.files);
    },
    handleFileInputUpload(e) {
      const input = e.target;
      this.pushFiles(input.files);
    },
    callFileInputUploadWindow() {
      this.$refs.fileInput.click();
    },
    pushFiles(files) {
      if (!files) {
        return;
      }

      for (const file of files) {
        if (!this.validateFile(file)) {
          return;
        }
        this.files.push(file);
      }
      this.$emit("change", this.files);
      this.$toast.add({
        severity: "success",
        detail: this.$t("toast.fileUpload.filesSuccesfullyAdded"),
        life: TOAST_LIFE,
      });
    },
    validateFile(file) {
      if (this.checkIfFileExists(file)) {
        this.$toast.add({
          severity: "error",
          detail: this.$t("toast.fileUpload.fileAlreadyExists"),
          life: TOAST_LIFE,
        });
        return false;
      }

      if (!this.isFileSizeValid(file)) {
        this.$toast.add({
          severity: "error",
          detail: this.$t("toast.fileUpload.fileIsTooLarge", {
            fileSize: this.formattedMaxFileSize,
          }),
          life: TOAST_LIFE,
        });
        return false;
      }

      if (!this.isFileTypeValid(file)) {
        this.$toast.add({
          severity: "error",
          detail: this.$t("toast.fileUpload.fileFormatInvalid"),
          life: TOAST_LIFE,
        });
        return false;
      }

      if (!this.isFileBelowFileLimit()) {
        this.$toast.add({
          severity: "error",
          detail: this.$t("toast.fileUpload.fileLimitReached", {
            fileLimit: this.fileLimit,
          }),
          life: TOAST_LIFE,
        });
        return false;
      }

      return true;
    },
    checkIfFileExists(file) {
      return this.files.findIndex((item) => item.name === file.name) > -1;
    },
    isFileSizeValid(file) {
      return file.size <= this.maxFileSize;
    },
    isFileTypeValid(file) {
      let isValid = false;

      for (const type of this.acceptedTypes) {
        if (this.isWildcard(type)) {
          isValid = this.getTypeClass(file.type) === this.getTypeClass(type);
        }

        if (isValid) {
          return true;
        }

        if (file.type === type) {
          isValid = true;
        } else {
          isValid =
            this.getFileExtension(file).toLowerCase() === type.toLowerCase();
        }

        if (isValid) {
          return true;
        }
      }

      return false;
    },
    isFileBelowFileLimit() {
      if (!this.fileLimit) {
        return true;
      }
      return this.files.length + 1 <= this.fileLimit;
    },
    removeFile(index) {
      const fileList = new DataTransfer();
      this.files.splice(index, 1);
      for (const file of this.files) {
        fileList.items.add(file);
      }
      this.$refs.fileInput.files = fileList.files;
      this.$emit("change", this.files);
    },
    isWildcard(fileType) {
      return fileType.indexOf("*") !== -1;
    },
    getFileExtension(file) {
      return "." + file.name.split(".").pop();
    },
    getTypeClass(fileType) {
      return fileType.substring(0, fileType.indexOf("/"));
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ validate, errors }"
    tag="div"
  >
    <div class="space-y-1">
      <BaseCard
        @drop.prevent="onDrop"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        variant="none"
        padding="none"
        ref="card"
        shadow="true"
        class="relative border-dashed bg-base-color-secondary p-3"
      >
        <input
          multiple
          ref="fileInput"
          type="file"
          :name="name"
          :accept="acceptedTypes"
          @input="handleFileInputUpload"
          class="pointer-events-none absolute inset-0 opacity-0"
        />
        <input
          type="hidden"
          :value="value"
          @change="validate"
          name="fileNames"
          :disabled="true"
        />
        <div class="space-y-2 text-center">
          <div>
            <BaseIcon icon="upload-simple" />
          </div>
          <div>
            <button
              class="p-button-link"
              @click.prevent="callFileInputUploadWindow"
            >
              {{ $t("content.clickToUpload") }}
            </button>
            {{ $t("content.orDragAndDrop") }}
          </div>
          <div class="text-xs text-neutral-foreground">
            {{ acceptedFormats }} (max. {{ formattedMaxFileSize }})
          </div>
        </div>
      </BaseCard>
      <FormMessage v-show="errors[0]" intent="danger">{{
        errors[0]
      }}</FormMessage>
    </div>

    <div class="mt-2 space-y-4">
      <BaseFileCard
        v-for="(file, index) in files"
        :key="`uploaded_file_${index}`"
        :fileName="file.name"
        :fileSize="file.size"
      >
        <template #buttons>
          <BaseButton
            icon="pi pi-times"
            iconPos="right"
            class="p-button p-button-icon p-button-secondary p-button-icon-sm"
            @click="removeFile(index)"
        /></template>
      </BaseFileCard>
    </div>
  </ValidationProvider>
</template>
