<script>
import BaseIcon from "@/components/ui/BaseIcon";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const icons = {
  success: "check",
  info: "check",
  warning: "check",
  danger: "x",
};

export default {
  name: "FormMessage",
  components: { BaseIcon },
  props: {
    intent: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      icon: icons[this.intent],
    };
  },
  watch: {
    intent: function (val) {
      this.icon = icons[val];
    },
  },
  computed: {
    className() {
      const variants = cva("flex items-start gap-1", {
        variants: {
          intent: {
            success: "[&>div:first-child]:bg-success text-success",
            info: "[&>div:first-child]:bg-info text-info",
            warning: "[&>div:first-child]:bg-warning text-warning",
            danger: "[&>div:first-child]:bg-danger text-danger",
          },
        },
      });
      return cn(variants({ intent: this.intent }), this.$attrs.className);
    },
  },
};
</script>
<template>
  <div :class="className">
    <div
      class="flex h-4 max-w-4 flex-grow items-center justify-center rounded-full p-[3px]"
    >
      <BaseIcon :icon="icon" class="invert" :toSvg="false" />
    </div>
    <div class="text-xs"><slot></slot></div>
  </div>
</template>
