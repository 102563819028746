<script>
import BaseButton from "@/components/ui/BaseButton";
import BaseForm from "@/components/ui/BaseForm.vue";
import BaseLabel from "@/components/ui/BaseLabel";
import BasePasswordInput from "@/components/ui/BasePasswordInput";
import BaseTextInput from "@/components/ui/BaseTextInput";
import FormGroup from "@/components/ui/FormGroup";
import LoginOrCreateAccountMessageLink from "@/components/ui/LoginOrCreateAccountMessageLink";
import TitleGroup from "@/components/ui/TitleGroup";
import { signIn } from "@/lib/utils";
import userCredentials from "@/schema/userCredentialsSchema";

export default {
  name: "LoginForm",
  components: {
    TitleGroup,
    FormGroup,
    BasePasswordInput,
    LoginOrCreateAccountMessageLink,
    BaseLabel,
    BaseButton,
    BaseTextInput,
    BaseForm,
  },
  data() {
    return {
      ...userCredentials,
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      signIn();
      this.$router.push({ name: "onboarding" });
    },
  },
};
</script>

<template>
  <div class="space-y-6">
    <TitleGroup
      :title="$t('content.logIn')"
      :subtitle="$t('content.welcomeBackToMarketplace')"
    />

    <BaseForm ref="form">
      <template #form-content>
        <FormGroup>
          <BaseLabel for="email">{{ $t("input.email.label") }}</BaseLabel>
          <BaseTextInput
            rules="email|required"
            id="email"
            :fieldName="$t('input.email.fieldName')"
            :placeholder="$t('input.email.placeholder')"
            v-model="email"
          />
        </FormGroup>
        <FormGroup>
          <BaseLabel for="password">{{ $t("input.password.label") }}</BaseLabel>
          <BasePasswordInput
            id="password"
            :placeholder="$t('input.password.placeholder')"
            v-model="password"
            :isLogin="true"
          />
        </FormGroup>
        <a href="#" class="p-button-link block text-sm">{{
          $t("content.forgotPassword")
        }}</a>
      </template>
    </BaseForm>

    <BaseButton
      :label="$t('button.logIn')"
      class="p-button-sm w-full text-center"
      @click="handleSubmit"
    />

    <LoginOrCreateAccountMessageLink action="signup" />
  </div>
</template>
