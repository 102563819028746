<script>
import BaseDropdown from "@/components/ui/BaseDropdown";

export default {
  name: "DiscoverDropdown",
  extends: BaseDropdown,
  components: {
    BaseDropdown,
  },
  computed: {
    discover() {
      return [
        { name: this.$t("discoveryOptions.television"), value: "television" },
        { name: this.$t("discoveryOptions.radio"), value: "radio" },
        { name: this.$t("discoveryOptions.facebook"), value: "facebook" },
        { name: this.$t("discoveryOptions.instagram"), value: "instagram" },
        { name: this.$t("discoveryOptions.press"), value: "press" },
        { name: this.$t("discoveryOptions.friends"), value: "friends" },
        { name: this.$t("discoveryOptions.events"), value: "events" },
        { name: this.$t("discoveryOptions.onlineEvents"), value: "online" },
        { name: this.$t("discoveryOptions.elsewhere"), value: "elsewhere" },
      ];
    },
    parentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
  },
};
</script>
<template>
  <BaseDropdown
    data-component="discover-dropdown"
    optionLabel="name"
    optionValue="value"
    :placeholder="$t('input.howDidYouDiscoverPhh.placeholder')"
    v-bind="parentProps"
    v-on="$listeners"
    :options="discover"
    :showClear="true"
  />
</template>
