<script>
import FormMessage from "@/components/ui/FormMessage";
import { getCountriesMap } from "@/lib/countries";
import parsePhoneNumber from "libphonenumber-js";
import { extend, ValidationProvider } from "vee-validate";
import CountryFlag from "vue-country-flag";
import BaseDropdown from "./BaseDropdown.vue";
import BaseTextInput from "./BaseTextInput.vue";
import { defaultLocale, i18n } from "@/setup/i18n-setup";
import { getLanguage } from "@/lib/utils";
import { EventBus } from "@/lib/event-bus";
import { EVENT_LANGUAGE_CHANGE } from "@/lib/constants";

let countryCode = null;

extend("phone-number", (value) => {
  const number = parsePhoneNumber(value, countryCode);
  if (!number || !number.isValid()) {
    return i18n.t("validation.invalidPhoneNumber");
  }
  return true;
});

export default {
  name: "PhoneNumberInput",
  inheritAttrs: false,
  components: {
    BaseTextInput,
    CountryFlag,
    BaseDropdown,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  model: {
    prop: "phoneNumber",
    event: "input",
  },
  data() {
    return {
      phoneNumber: this.$attrs.phoneNumber || null,
      phoneNumberWithoutPrefix: null,
      countryCode: "LT",
      isValid: false,
      countries: getCountriesMap,
    };
  },
  watch: {
    countryCode(val, prevValue) {
      if (!val) {
        return;
      }
      this.countryCode = val;

      const currentDialCode = this.getOptionByCode(val)?.dialCode;
      const prevDialCode = this.getOptionByCode(prevValue)?.dialCode;

      if (!this.phoneNumber && currentDialCode) {
        this.phoneNumber = `+${currentDialCode}`;
        return;
      }

      if (this.phoneNumber.startsWith(`+${prevDialCode}`) && currentDialCode) {
        this.phoneNumber = this.phoneNumber.replace(
          `+${prevDialCode}`,
          `+${currentDialCode}`
        );
      }

      this.validatePhoneNumber(this.phoneNumber);
      this.$emit("input", this.phoneNumber);
    },
  },
  mounted() {
    if (!this.phoneNumber) {
      this.phoneNumber = `+${this.selectedOption?.dialCode}`;
    }

    const locale = getLanguage()?.toUpperCase();

    if (locale === defaultLocale.toUpperCase()) {
      this.countryCode = null;
    } else {
      this.countryCode = locale;
    }

    EventBus.$on(EVENT_LANGUAGE_CHANGE, (lang) => {
      if (!lang) {
        return;
      }

      const locale = lang?.toUpperCase();

      if (locale === defaultLocale.toUpperCase()) {
        this.countryCode = null;
        return;
      }

      this.countryCode = locale;
    });
  },
  beforeDestroy() {
    EventBus.$off(EVENT_LANGUAGE_CHANGE);
  },
  computed: {
    selectedOption() {
      return this.getOptionByCode(this.countryCode);
    },
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
  methods: {
    getOptionByCode(countryCode) {
      return this.countries.find((item) => item.code === countryCode);
    },
    onDropdownChange(option) {
      this.countryCode = option.value;
    },
    onInput(value) {
      this.phoneNumber = `+${this.selectedOption?.dialCode}${value}`;
      this.phoneNumberWithoutPrefix = value;
      this.validatePhoneNumber(this.phoneNumber);
      this.$emit("input", this.phoneNumber);
    },
    validatePhoneNumber(value) {
      const number = parsePhoneNumber(value, this.countryCode);
      this.isValid = number ? number.isValid() : false;
    },
    setCountryCode(value) {
      const number = parsePhoneNumber(value, this.countryCode);
      this.countryCode = number ? number.country : null;
    },
  },
};
</script>

<template>
  <div class="relative grid grid-cols-[120px,1fr] gap-4">
    <BaseDropdown
      data-component="phone-prefix-dropdown"
      optionLabel="label"
      optionValue="code"
      :placeholder="$t('input.phoneNumber.dropdownPlaceholder')"
      :filterPlaceholder="$t('input.phoneNumber.filterPlaceholder')"
      @change="onDropdownChange"
      :value="countryCode"
      :options="countries"
      :filter="true"
    >
      <template #value="slotProps">
        <div data-value class="flex items-center gap-2" v-if="slotProps.value">
          <div class="h-5 w-5 overflow-hidden rounded-lg">
            <CountryFlag :country="slotProps.value" size="normal" />
          </div>
          <div>+{{ selectedOption.dialCode }}</div>
        </div>
        <span v-else>{{ slotProps.placeholder }}</span>
      </template>
      <template #option="slotProps">
        <div class="flex items-center gap-2">
          <div class="h-5 w-5 overflow-hidden rounded-lg">
            <CountryFlag :country="slotProps.option.code" size="normal" />
          </div>
          <div class="flex flex-1 items-center gap-2">
            <div class="text-sm text-text-primary">
              {{ slotProps.option.name }}
            </div>
            <div class="text-sm text-neutral-foreground">
              +{{ slotProps.option.dialCode }}
            </div>
          </div>
        </div>
      </template>
    </BaseDropdown>
    <div>
      <BaseTextInput
        id="phoneNumber"
        :placeholder="$t('input.phoneNumber.placeholder')"
        :fieldName="$t('input.phoneNumber.fieldName')"
        :value="phoneNumberWithoutPrefix"
        @input="onInput"
      />
      <ValidationProvider
        v-bind="validationProviderProps"
        v-on="$listeners"
        v-slot="{ errors }"
        class="space-y-1"
        tag="div"
      >
        <input
          type="hidden"
          v-bind="parentProps"
          v-on="$listeners"
          :value="phoneNumber"
        />
        <FormMessage v-show="errors[0]" intent="danger">{{
          errors[0]
        }}</FormMessage>
      </ValidationProvider>
    </div>
  </div>
</template>

<style scoped>
:deep(.normal-flag.flag) {
  transform: scale(0.6);
  margin: -16px 0px -11px -16px;
}
</style>
