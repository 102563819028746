<script>
import PrimePasswordInput from "primevue/password";
import FormMessage from "@/components/ui/FormMessage";
import { ValidationProvider } from "vee-validate";
import { EventBus } from "@/lib/event-bus";
import { EVENT_LANGUAGE_CHANGE } from "@/lib/constants";

export default {
  name: "BasePasswordInput",
  extends: PrimePasswordInput,
  components: {
    PrimePasswordInput,
    FormMessage,
    ValidationProvider,
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validationState() {
      return [
        {
          regex: /(?=.{5,})/, // at min 5 characters
          message: this.$t("validation.atLeastCharactersLong", {
            characters: 5,
          }),
          intent: "danger",
        },
        {
          regex: /(?=.*[0-9])/, // contains a number
          message: this.$t("validation.containsAtLeastOneNumber"),
          intent: "danger",
        },
        {
          regex: /^(?=.*[a-z])(?=.*[A-Z])/, // at least 1 upper case and 1 lower case
          message: this.$t("validation.containsUpperAndLowerLetters"),
          intent: "danger",
        },
      ];
    },
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
    regexString() {
      return RegExp(
        this.validationState
          .map((item) =>
            item.regex.toString().substring(1, item.regex.toString().length - 1)
          )
          .join("")
      );
    },
    ruleSet() {
      const rules = { required: true };

      if (!this.isLogin) {
        rules.regex = this.regexString;
      }

      return rules;
    },
  },
  methods: {
    checkPasswordStrength(value) {
      if (this.isLogin) {
        return;
      }
      this.validationState.forEach(
        (item, index) =>
          (this.validationState[index].intent = item.regex.test(value)
            ? "success"
            : "danger")
      );
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.checkPasswordStrength(this.$props.value);

      EventBus.$on(EVENT_LANGUAGE_CHANGE, () => {
        this.checkPasswordStrength(this.$props.value);
      });
    });
  },
  beforeDestroy() {
    EventBus.$off(EVENT_LANGUAGE_CHANGE);
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="parentProps"
    v-on="$listeners"
    v-slot="{ failedRules, errors }"
    :rules="ruleSet"
    :name="$t('input.password.fieldName')"
    class="space-y-1"
    tag="div"
  >
    <PrimePasswordInput
      v-bind="parentProps"
      v-on="$listeners"
      :toggleMask="true"
      :feedback="false"
      :value="value"
      class="w-full"
      @input="checkPasswordStrength"
      inputClass="w-full input"
    />

    <FormMessage v-if="errors[0] && failedRules.required" intent="danger">
      {{ errors[0] }}
    </FormMessage>

    <div v-if="!isLogin" class="space-y-1">
      <FormMessage
        v-for="(item, index) in validationState"
        :key="`password_strength_message_${index}`"
        v-show="value"
        :intent="item.intent"
      >
        {{ item.message }}
      </FormMessage>
    </div>
  </ValidationProvider>
</template>
