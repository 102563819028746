import clsx from "clsx";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import { DATE_FORMAT } from "./constants";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * @description A rudamentary check to see if the user is authenticated
 * @returns boolean
 */
export const isAuthenticated = () => {
  return localStorage.getItem("loggedIn") === "true";
  // return vm.$router.currentRoute.path.includes("flow");
};

/**
 * @description A rudamentary check ot isee if the user has reached the "onboarding" part of the app
 * @param {Vue} vm
 * @returns boolean
 */
export const isOnboarding = (vm) => {
  return vm.$route.path.includes("step");
};

/**
 * @description A rudamentary check ot isee if the user has reached the "internal flow" part of the app
 * @param {Vue} vm
 * @returns boolean
 */
export const isInternalFlow = (vm) => {
  return vm.$route.path.includes("flow");
};

/**
 * @description A check to see if the current view is "inbound" or "outbound"
 * ?type=inbound
 * @param {Vue} vm
 * @returns boolean
 */
export const isInbound = (vm) => {
  return vm.$route.query.type === "inbound";
};

export const verifyEmail = () => {
  if (typeof window === undefined) {
    return;
  }
  localStorage.setItem("emailVerified", "true");
};

export const isEmailVerified = () => {
  if (typeof window === undefined) {
    return;
  }
  return localStorage.getItem("emailVerified") === "true";
};

export const signIn = () => {
  if (typeof window === undefined) {
    return;
  }
  localStorage.setItem("loggedIn", "true");
};

export const signOut = () => {
  if (typeof window === undefined) {
    return;
  }
  localStorage.removeItem("loggedIn");
};

export const setLanguage = (lang) => {
  if (typeof window === undefined) {
    return;
  }
  localStorage.setItem("lang", lang);
};

export const getLanguage = () => {
  if (typeof window === undefined) {
    return;
  }
  return localStorage.getItem("lang");
};

/**
 * @description PrimeVue calculates the panel position and appends it using the top coordinate.
 * We are styling the dropdown panels and adding a 8px gap to them. When the panel opens up to the top,
 * The panel is then touching the input. This checks if the panel has opened to the top and adds a -5px to translateY to offset it.
 * @param {HTMLElement} panel the dropdown panel element
 * @returns void
 */
export const applyReverseTranslateYToDropdownPanel = (panel) => {
  if (!panel) {
    return;
  }
  const panelTopPosition = parseInt(panel.style.top, 10);
  if (panelTopPosition < 0) {
    panel.style.transform = `translateY(-8px)`;
    return;
  }

  panel.style.transform = null;
};

/**
 * @description PrimeVue will recalculate dropdown panel's top/bottom placemenet based on intersection with the viewport (bottom or top of the window)
 * If, for example, the dropdown has 0 results and is slightly touching the bottom of the window, it will push the panel to the top and lose a ~8px gap
 * We can use this handler to apply specific styles based on the panel's size
 * @param {HTMLElement} panel - the dropdown panel element
 * @param {(panel: HTMLElement, observerInstance: ResizeObserver) => void} callback - the callback to apply specific styles or changes
 * @returns ResizeObserver
 */
export const handleDropdownPanelSizeChanges = (panel, callback) => {
  if (!panel || !callback) {
    return;
  }

  const observer = new ResizeObserver((observerInstance) =>
    callback(panel, observerInstance)
  );

  observer.observe(panel);

  return observer;
};

export const formatDate = (date) => {
  return format(new Date(date), DATE_FORMAT);
};
