<script>
import BaseDropdown from "@/components/ui/BaseDropdown";

export default {
  name: "ShippingMethodDropdown",
  extends: BaseDropdown,
  components: {
    BaseDropdown,
  },
  computed: {
    shippingMethods() {
      return [
        {
          name: this.$t("shippingMethods.crossDocking"),
          value: "cross-docking",
        },
        {
          name: this.$t("shippingMethods.directDelivery"),
          value: "direct-delivery",
        },
      ];
    },
    parentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
  },
};
</script>
<template>
  <BaseDropdown
    data-component="shipping-methods-dropdown"
    optionLabel="name"
    optionValue="value"
    :placeholder="$t('input.shippingMethod.placeholder')"
    v-bind="parentProps"
    v-on="$listeners"
    :options="shippingMethods"
    :showClear="true"
  />
</template>
