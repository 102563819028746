<script>
import StartSellingView from "@/components/views/documents-sent/StartSellingView.vue";

export default {
  name: "StartSellingPage",
  components: {
    StartSellingView,
  },
};
</script>

<template>
  <StartSellingView />
</template>
