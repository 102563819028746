<script>
import FormMessage from "@/components/ui/FormMessage";
import InputText from "primevue/inputtext";
import { ValidationProvider } from "vee-validate";

export default {
  name: "BaseTextInput",
  extends: InputText,
  components: {
    InputText,
    FormMessage,
    ValidationProvider,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  computed: {
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    class="space-y-1"
    tag="div"
  >
    <InputText v-bind="parentProps" v-on="$listeners" class="input w-full" />
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>
