<script>
import { cn } from "@/lib/utils";

export default {
  name: "BaseSeparator",
  props: {
    orientation: {
      type: String,
      default: "horizontal", // | 'vertical'
    },
  },
  computed: {
    className() {
      return cn(
        "bg-neutral-secondary",
        this.orientation === "horizontal" ? "h-[1px]" : "w-[1px]"
      );
    },
  },
};
</script>

<template>
  <div :class="className"></div>
</template>
