<script>
import ConfirmEmailView from "./ConfirmEmailView.vue";
import EmailVerificationLinkExpiredView from "./EmailVerificationLinkExpiredView.vue";

export default {
  name: "ConfirmEmailWrapperView",
  components: {
    ConfirmEmailView,
    EmailVerificationLinkExpiredView,
  },
  data() {
    return {
      // confirm-email?isEmailLinkExpired=true
      isEmailLinkExpired: this.$route?.query?.isEmailLinkExpired === "true",
    };
  },
};
</script>
<template>
  <EmailVerificationLinkExpiredView v-if="isEmailLinkExpired" />
  <ConfirmEmailView v-else />
</template>
