<script>
import PrimeSidebar from "primevue/sidebar";

export default {
  name: "BaseSidebar",
  extends: PrimeSidebar,
  components: { PrimeSidebar },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>
<template>
  <PrimeSidebar v-bind="props" v-on="$listeners" position="right">
    <template #header>
      <slot name="header">{{ $t("content.help") }}</slot>
    </template>
    <slot></slot>
  </PrimeSidebar>
</template>
