import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/lang/en";
import { getLanguage, setLanguage } from "@/lib/utils";
import { EventBus } from "@/lib/event-bus";
import { EVENT_LANGUAGE_CHANGE_COMPLETE } from "@/lib/constants";

Vue.use(VueI18n);

export const defaultLocale = "en";

export const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: defaultLocale,
  messages, // set locale messages
});

const loadedLanguage = getLanguage();

const loadedLanguages = [defaultLocale]; // our default language that is preloaded

if (!loadedLanguages.includes(loadedLanguage)) {
  import(`@/lang/${loadedLanguage}.js`).then((messages) => {
    i18n.setLocaleMessage(loadedLanguage, messages.default[loadedLanguage]);
    loadedLanguages.push(loadedLanguage);
  });
}

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  setLanguage(lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default[lang]);
    loadedLanguages.push(lang);
    EventBus.$emit(EVENT_LANGUAGE_CHANGE_COMPLETE, lang);
    return setI18nLanguage(lang);
  });
}
