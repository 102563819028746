<script>
import { isEmailVerified, verifyEmail } from "@/lib/utils";
import ConfirmEmailWrapperView from "../views/confirm-email/ConfirmEmailWrapperView.vue";

export default {
  name: "ConfirmEmailPage",
  components: {
    ConfirmEmailWrapperView,
  },
  data() {
    return {
      isEmailLinkExpired: this.$route?.query?.isEmailLinkExpired === "true",
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.isEmailLinkExpired) {
        return;
      }

      if (!isEmailVerified()) {
        return verifyEmail();
      }
      this.$router.push({ name: "onboarding" });
    });
  },
};
</script>

<template>
  <ConfirmEmailWrapperView />
</template>
