<script>
import BaseButton from "@/components/ui/BaseButton";
import LoginOrCreateAccountMessageLink from "@/components/ui/LoginOrCreateAccountMessageLink";
import TitleGroup from "@/components/ui/TitleGroup";
import CompanyDetailsForm from "@/components/forms/CompanyDetailsForm";

export default {
  name: "CompanyDetailsFormView",
  components: {
    TitleGroup,
    LoginOrCreateAccountMessageLink,
    BaseButton,
    CompanyDetailsForm,
  },
  props: {
    nextStep: {
      type: Function,
    },
  },
  data() {
    return {
      ...this.$attrs,
    };
  },
  methods: {
    async handleNextStep() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.$props.nextStep();
    },
  },
};
</script>

<template>
  <div class="space-y-6">
    <TitleGroup
      :title="$t('content.shareSomeDetailsOfTheCompany')"
      :subtitle="$t('content.startSellingOnPhh')"
    />

    <CompanyDetailsForm ref="form" :formData="formData" />

    <BaseButton
      :label="$t('button.next')"
      iconPos="right"
      icon="pi pi-angle-right"
      class="p-button-sm w-full text-center"
      @click="handleNextStep"
    />

    <LoginOrCreateAccountMessageLink />
  </div>
</template>
