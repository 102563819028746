<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import FormMessage from "@/components/ui/FormMessage.vue";
import { DATE_FORMAT, EVENT_LANGUAGE_CHANGE_COMPLETE } from "@/lib/constants";
import { EventBus } from "@/lib/event-bus";
import {
  applyReverseTranslateYToDropdownPanel,
  formatDate,
  handleDropdownPanelSizeChanges,
} from "@/lib/utils";
import { i18n } from "@/setup/i18n-setup";
import { isDate, isMatch, isValid } from "date-fns";
import PrimeCalendar from "primevue/calendar";
import { ValidationProvider, extend } from "vee-validate";

extend("date", (value) => {
  if (!isMatch(value, DATE_FORMAT)) {
    return i18n.t("validation.invalidDate");
  }
  return true;
});

export default {
  name: "BaseCalendar",
  extends: PrimeCalendar,
  components: {
    PrimeCalendar,
    BaseButton,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  data() {
    return {
      formattedDate: null,
    };
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
    selectedDate() {
      return this.$props.value || null;
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Set initial PrimeVue Calendar locale
      this.$primevue.config.locale.dayNamesMin = this.$t("content.dayNamesMin");
      this.$primevue.config.locale.monthNames = this.$t("content.monthNames");
      this.$primevue.config.locale.monthNamesShort = this.$t(
        "content.monthNamesShort"
      );

      // When new language model is imported, set the locale
      EventBus.$on(EVENT_LANGUAGE_CHANGE_COMPLETE, (locale) => {
        this.$primevue.config.locale.dayNamesMin =
          this.$i18n.messages[locale]?.content?.dayNamesMin;
        this.$primevue.config.locale.monthNames =
          this.$i18n.messages[locale]?.content?.monthNames;
        this.$primevue.config.locale.monthNamesShort =
          this.$i18n.messages[locale]?.content?.monthNamesShort;
      });

      const input = this.$refs.calendar?.$el?.firstChild;

      if (!input) {
        return;
      }

      // node_modules/primevue/calendar/Calendar.vue:3 inputmode is set to "none" by default
      // This will prevent mobile devices from triggering virtual keyboard
      input.setAttribute("inputmode", "numeric");
    });
  },
  beforeDestroy() {
    EventBus.$off(EVENT_LANGUAGE_CHANGE_COMPLETE);
  },
  methods: {
    showCalendar() {
      const wrapper = this.$refs.calendar?.$el;
      if (!wrapper.querySelector(".p-datepicker")) {
        // When the calendar icon on the input is clicked, set inputmode to none in order to prevent triggering the virtual keyboard
        wrapper.firstChild.setAttribute("inputmode", "none");
        wrapper.firstChild.focus();
      } else {
        // When the calendar overlay loses focus, revert to inputmode "numeric" in order to have virtual keyboard trigger in case the users clicks on the input itself
        wrapper.firstChild.setAttribute("inputmode", "numeric");
        wrapper.firstChild.blur();
      }
    },
    onHideCalendar() {
      // When the calendar overlay loses focus, revert to inputmode "numeric" in order to have virtual keyboard trigger in case the users clicks on the input itself
      this.$refs.calendar?.$el.firstChild.setAttribute("inputmode", "numeric");
    },
    applyReverseTranslateY() {
      const panel = this.$refs.calendar?.$el.querySelector(".p-datepicker");
      handleDropdownPanelSizeChanges(
        panel,
        applyReverseTranslateYToDropdownPanel
      );
    },
    setFormattedDateValue(value) {
      const input = this.$refs.calendar?.$el?.firstChild;
      const formattedDate = formatDate(value);
      this.formattedDate = formattedDate;
      input.value = formattedDate;
      this.$emit("input", formattedDate);
    },
    onInput(value) {
      if (isDate(value) && isValid(new Date(value))) {
        this.setFormattedDateValue(value);
        return;
      }
      this.$emit("input", value);
      this.formattedDate = value;
    },
    onDateSelect(value) {
      if (
        (isValid(new Date(value)) && isDate(value)) ||
        (isValid(new Date(value)) && isMatch(value, DATE_FORMAT))
      ) {
        this.setFormattedDateValue(value);
        return;
      }
    },
    onClearButtonClick() {
      this.formattedDate = null;
      this.$emit("input", null);
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    tag="div"
    class="space-y-1"
  >
    <div class="relative flex items-center">
      <PrimeCalendar
        ref="calendar"
        v-bind="props"
        v-on="$listeners"
        dateFormat="yy/mm/dd"
        :placeholder="$t('input.dateOfBirth.placeholder')"
        :selectOtherMonths="true"
        :value="formattedDate"
        @blur="(e) => onDateSelect(e.target.value)"
        @date-select="onDateSelect"
        @input="onInput"
        @show="applyReverseTranslateY"
        @hide="onHideCalendar"
      >
        <template #footer>
          <div class="flex justify-end border-t pt-4">
            <BaseButton
              :label="$t('button.clear')"
              class="p-button p-button-sm p-button-secondary"
              @click="onClearButtonClick"
            />
          </div>
        </template>
      </PrimeCalendar>
      <span
        v-if="!formattedDate"
        role="button"
        class="pi pi-calendar absolute right-2 text-neutral-foreground"
        @click.stop="showCalendar"
      ></span>
      <span
        v-else
        role="button"
        class="pi pi-times clear-icon absolute right-2"
        @click="onClearButtonClick"
      ></span>
    </div>
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>
