<script>
import PrimeButton from "primevue/button";

export default {
  name: "BaseButton",
  extends: PrimeButton,
  components: {
    PrimeButton,
  },
  computed: {
    parentProps() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>

<template>
  <PrimeButton v-bind="parentProps" v-on="$listeners">
    <slot></slot>
  </PrimeButton>
</template>

<style scoped>
.p-button {
  height: var(--input-height);
  border-radius: var(--radius);
  border-color: var(--primary-color);
  background: var(--primary-color);
  box-shadow: var(--shadow);
  font-weight: 600;
}

.p-button.p-button-sm {
  padding: 10px 12px;
}

.p-button.p-button-icon {
  padding: 10px;
}

.p-button.p-button-icon-sm {
  width: 24px;
  height: 24px;
}

.p-button.p-button-icon-sm >>> span.p-button-icon {
  font-size: 12px;
}

.p-button.p-button:enabled:focus {
  box-shadow: none;
}

.p-button:disabled,
.p-button.p-button-secondary:disabled {
  background: var(--secondary-color-hover);
  color: var(--text-color-disabled);
  border-color: var(--secondary-color-hover);
}

@media (hover: hover) {
  .p-button:enabled:hover {
    background: var(--primary-color-hover);
    border-color: var(--primary-color);
  }
}

.p-button.p-button-secondary {
  background: var(--secondary-color);
  color: var(--secondary-color-text);
  border-color: var(--border-color);
}

.p-button.p-button-secondary:disabled {
  background: var(--secondary-color-hover);
  border-color: var(--neutral-hover);
  color: var(--text-color-disabled);
}

@media (hover: hover) {
  .p-button.p-button-secondary:enabled:hover {
    background: var(--secondary-color-hover);
    border-color: var(--neutral-hover);
    color: var(--secondary-color-text);
  }
}
</style>
