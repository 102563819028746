<script>
export default {
  name: "LoginOrCreateAccountMessageLink",
  props: {
    action: {
      type: String, // login | signup
      default: "login",
    },
    message: {
      type: String,
    },
    href: {
      type: String,
    },
    callToAction: {
      type: String,
    },
  },
  computed: {
    data() {
      return {
        login: {
          message: this.message ?? this.$t("content.alreadyHaveAnAccount"),
          href: this.href ?? "/login",
          callToAction: this.callToAction ?? this.$t("content.logIn"),
        },
        signup: {
          message: this.message ?? this.$t("content.dontHaveAnAccount"),
          href: this.href ?? "/",
          callToAction: this.callToAction ?? this.$t("content.signUp"),
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex items-center gap-2 text-sm">
    <span class="text-neutral-foreground">{{ data[action].message }}</span>
    <router-link :to="data[action].href" class="p-button-link">{{
      data[action].callToAction
    }}</router-link>
  </div>
</template>
