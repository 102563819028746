<script>
export default {
  name: "ContactUsEmailAddress",
};
</script>

<template>
  <a :href="`mailto:${$t('content.contactEmailAddress')}`">{{
    $t("content.contactEmailAddress")
  }}</a>
</template>
