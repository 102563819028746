<script>
import BaseSeparator from "@/components/ui/BaseSeparator.vue";
import BaseIcon from "./BaseIcon.vue";

export default {
  name: "BaseSteps",
  props: {
    /** @param {Array<{ label?: string, to: string, name?: string, isValid: boolean, completed: boolean }>} model */
    model: {
      type: Array,
    },
  },
  components: {
    BaseSeparator,
    BaseIcon,
  },
  methods: {
    getActiveStepStyles(isActive) {
      return isActive
        ? "bg-primary text-white hover:text-white"
        : "bg-neutral text-neutral-foreground hover:text-neutral-foreground";
    },
  },
};
</script>

<template>
  <div
    class="mx-auto flex w-full flex-grow items-center justify-between gap-4 md:max-w-[512px]"
  >
    <template v-for="(item, index) in model">
      <router-link
        :key="`step_indicator_${index}`"
        :to="{ [item.name ? 'name' : 'path']: item.name || item.to }"
        :append="true"
        custom
        tag="a"
        v-slot="{ isActive, navigate, href }"
        class="flex h-6 w-full max-w-6 items-center justify-center rounded-full font-geist text-xs font-semibold"
      >
        <a
          v-if="!item.completed"
          :class="getActiveStepStyles(isActive)"
          :href="href"
          @click.prevent="navigate({ path: item.to })"
        >
          {{ index + 1 }}
        </a>
        <a
          v-else
          class="bg-primary-light text-primary hover:text-primary"
          :href="href"
          @click.prevent="navigate({ path: item.to })"
        >
          <BaseIcon
            icon="check"
            class="h-4 w-4 self-baseline [&_path]:fill-primary"
            v-if="!isActive"
          />
          <span v-if="isActive">{{ index + 1 }}</span>
        </a>
      </router-link>
      <BaseSeparator
        :key="`step_separator_${index}`"
        v-if="index + 1 !== model.length"
        class="w-full flex-grow"
      />
    </template>
  </div>
</template>
