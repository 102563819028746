var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PrimeToast',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"message",fn:function(slotProps){return [_c('div',{staticClass:"flex w-full gap-2"},[_c('div',{staticClass:"h-5 w-5"},[_c('BaseIcon',{staticClass:"alert-icon",attrs:{"icon":slotProps.message.severity === 'success'
              ? 'check-circle-fill'
              : slotProps.message.severity === 'error'
              ? 'x-circle-fill'
              : slotProps.message.severity === 'warn'
              ? 'warning-circle-fill'
              : slotProps.message.severity === 'info'
              ? 'info-fill'
              : ''}})],1),_c('div',{staticClass:"flex-1"},[(slotProps.message.summary)?_c('div',[_vm._v(" "+_vm._s(slotProps.message.summary)+" ")]):_vm._e(),(slotProps.message.detail)?_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(slotProps.message.detail)+" ")]):_vm._e()])])]}}])},'PrimeToast',_vm.props,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }