<script>
import BaseIcon from "@/components/ui/BaseIcon";
import PrimeToast from "primevue/toast";

export default {
  name: "BaseToast",
  components: {
    PrimeToast,
    BaseIcon,
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>

<template>
  <PrimeToast v-bind="props" v-on="$listeners">
    <template #message="slotProps">
      <div class="flex w-full gap-2">
        <div class="h-5 w-5">
          <BaseIcon
            class="alert-icon"
            :icon="
              slotProps.message.severity === 'success'
                ? 'check-circle-fill'
                : slotProps.message.severity === 'error'
                ? 'x-circle-fill'
                : slotProps.message.severity === 'warn'
                ? 'warning-circle-fill'
                : slotProps.message.severity === 'info'
                ? 'info-fill'
                : ''
            "
          />
        </div>
        <div class="flex-1">
          <div v-if="slotProps.message.summary">
            {{ slotProps.message.summary }}
          </div>
          <div v-if="slotProps.message.detail" class="text-sm">
            {{ slotProps.message.detail }}
          </div>
        </div>
      </div>
    </template>
  </PrimeToast>
</template>
