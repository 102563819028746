<script>
import HeroBackground from "@/assets/images/backgrounds/hero-background.png";
import LV220Logo from "@/assets/images/logos/220lv.png";
import HobbyHallLogo from "@/assets/images/logos/hobbyhall.png";
import Kaup24Logo from "@/assets/images/logos/kaup24.png";
import PhhGroupLogo from "@/assets/images/logos/phh-group-logo.png";
import PigultLogo from "@/assets/images/logos/pigult.png";
import BaseIcon from "@/components/ui/BaseIcon";
import BaseCard from "@/components/ui/BaseCard";

export default {
  name: "HeroSection",
  data() {
    return {
      phhGroupLogo: PhhGroupLogo,
      kaup24Logo: Kaup24Logo,
      pigultLogo: PigultLogo,
      hobbyHallLogo: HobbyHallLogo,
      lv220Logo: LV220Logo,
      heroBackground: HeroBackground,
    };
  },
  components: {
    BaseIcon,
    BaseCard,
  },
};
</script>

<template>
  <div>
    <div
      :style="{ background: `url('${heroBackground}')` }"
      class="pointer-events-none absolute inset-0 z-0 !bg-cover !bg-center !bg-no-repeat opacity-10"
    ></div>
    <div class="relative max-w-[471px]">
      <div class="relative mb-14 h-[300px] w-full">
        <div
          class="circle-dashed m-auto flex h-[300px] w-full max-w-[300px] items-center justify-center p-16"
        >
          <BaseCard variant="light" padding="md" border="none">
            <img :src="phhGroupLogo" alt="PHH Group logo" class="invert" />
          </BaseCard>

          <BaseCard
            variant="dark"
            border="none"
            padding="md"
            className="border-none absolute top-[0px] left-0 lg:left-[-8px] max-w-[133px]"
          >
            <img :src="kaup24Logo" alt="Kaup24.ee logo" />
            <div
              class="absolute bottom-0 right-0 hidden h-2 w-2 rounded-full bg-primary shadow-[0_0_0_3px_#fff] lg:block"
            ></div>
          </BaseCard>

          <BaseCard
            variant="dark"
            border="none"
            padding="md"
            className="absolute top-[-20px] lg:top-[-20px] right-0 max-w-[145px]"
          >
            <img :src="hobbyHallLogo" alt="HobbyHall.fi logo" />
            <div
              class="absolute bottom-0 left-0 hidden h-2 w-2 rounded-full bg-primary shadow-[0_0_0_3px_#fff] lg:block"
            ></div>
          </BaseCard>

          <BaseCard
            variant="dark"
            border="none"
            padding="md"
            className="absolute bottom-[55px] right-0 lg:right-[-12px] max-w-[106px]"
          >
            <img :src="lv220Logo" alt="220.lv logo" />
            <div
              class="absolute left-0 top-0 hidden h-2 w-2 rounded-full bg-primary shadow-[0_0_0_3px_#fff] lg:block"
            ></div>
          </BaseCard>

          <BaseCard
            variant="dark"
            border="none"
            padding="md"
            className="absolute bottom-[0px] lg:bottom-0 left-[18px] max-w-[105px]"
          >
            <img :src="pigultLogo" alt="Pigu.lt logo" />
            <div
              class="absolute right-0 top-0 hidden h-2 w-2 rounded-full bg-primary shadow-[0_0_0_3px_#fff] lg:block"
            ></div>
          </BaseCard>
        </div>
      </div>
      <div class="mb-6">
        <h2 class="font-semibold text-white">
          {{ $t("content.slogan") }}
        </h2>
      </div>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <BaseCard
          variant="dark"
          border="none"
          padding="md"
          className="flex gap-4"
        >
          <div class="h-6 w-6 max-w-6 flex-grow">
            <BaseIcon icon="globe" class="invert" />
          </div>
          <div class="space-y-1">
            <p class="font-semibold">
              {{ $t("content.potentialBuyers.number") }}
            </p>
            <p class="text-sm text-neutral-secondary">
              {{ $t("content.potentialBuyers.label") }}
            </p>
          </div>
        </BaseCard>
        <BaseCard
          variant="dark"
          border="none"
          padding="md"
          className="flex gap-4"
        >
          <div class="h-6 w-6 max-w-6 flex-grow">
            <BaseIcon icon="users-three" class="invert" />
          </div>
          <div class="space-y-1">
            <p class="font-semibold">
              {{ $t("content.activeBuyers.number") }}
            </p>
            <p class="text-sm text-neutral-secondary">
              {{ $t("content.activeBuyers.label") }}
            </p>
          </div>
        </BaseCard>
        <BaseCard
          variant="dark"
          border="none"
          padding="md"
          className="flex gap-4"
        >
          <div class="h-6 w-6 max-w-6 flex-grow">
            <BaseIcon icon="desktop" class="invert" />
          </div>
          <div class="space-y-1">
            <p class="font-semibold">
              {{ $t("content.buyerVisitsEveryday.number") }}
            </p>
            <p class="text-sm text-neutral-secondary">
              {{ $t("content.buyerVisitsEveryday.label") }}
            </p>
          </div>
        </BaseCard>
        <BaseCard
          variant="dark"
          border="none"
          padding="md"
          className="flex gap-4"
        >
          <div class="h-6 w-6 max-w-6 flex-grow">
            <BaseIcon icon="package" class="invert" />
          </div>
          <div class="space-y-1">
            <p class="font-semibold">
              {{ $t("content.ordersFulfilledPerYear.number") }}
            </p>
            <p class="text-sm text-neutral-secondary">
              {{ $t("content.ordersFulfilledPerYear.label") }}
            </p>
          </div>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<style scoped>
.circle-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='300' ry='300' stroke='%23FFF' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
