<script>
import BaseDropdown from "@/components/ui/BaseDropdown";

export default {
  name: "DeliveryAddressDropdown",
  extends: BaseDropdown,
  components: {
    BaseDropdown,
  },
  computed: {
    deliveryAddresses() {
      return [
        {
          name: this.$t("deliveryAddresses.lithuania"),
          value: "lithuania",
        },
        { name: this.$t("deliveryAddresses.latvia"), value: "latvia" },
        {
          name: this.$t("deliveryAddresses.estonia"),
          value: "estonia",
        },
        {
          name: this.$t("deliveryAddresses.finland"),
          value: "finland",
        },
        {
          name: this.$t("deliveryAddresses.poland"),
          value: "poland",
        },
      ];
    },
    parentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
  },
};
</script>
<template>
  <BaseDropdown
    data-component="delivery-address-dropdown"
    optionLabel="name"
    optionValue="value"
    :placeholder="$t('input.deliveryAddress.placeholder')"
    v-bind="parentProps"
    v-on="$listeners"
    :options="deliveryAddresses"
    :showClear="true"
  />
</template>
