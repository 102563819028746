<script>
import OnboardingStepsView from "@/components/views/OnboardingStepsView.vue";

export default {
  name: "OnboardingStepsPage",
  components: {
    OnboardingStepsView,
  },
};
</script>

<template>
  <OnboardingStepsView />
</template>
