<script>
import CreateAccountInboundFormView from "@/components/forms/create-account-inbound/CreateAccountInboundFormView.vue";

export default {
  name: "HomePage",
  components: { CreateAccountInboundFormView },
};
</script>

<template>
  <CreateAccountInboundFormView />
</template>
