<script>
import PrimeDropdown from "primevue/dropdown";
import { ValidationProvider } from "vee-validate";
import FormMessage from "@/components/ui/FormMessage";
import {
  applyReverseTranslateYToDropdownPanel,
  handleDropdownPanelSizeChanges,
} from "@/lib/utils";

export default {
  name: "BaseDropdown",
  extends: PrimeDropdown,
  components: {
    PrimeDropdown,
    ValidationProvider,
    FormMessage,
  },
  props: {
    fieldName: {
      type: String,
    },
  },
  computed: {
    parentProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
    validationProviderProps() {
      return { rules: this.$attrs.rules, name: this.$props.fieldName || "" };
    },
  },
  methods: {
    applyReverseTranslateY() {
      const panel = this.$refs.dropdown.$el.querySelector(".p-dropdown-panel");
      handleDropdownPanelSizeChanges(
        panel,
        applyReverseTranslateYToDropdownPanel
      );
    },
  },
};
</script>

<template>
  <ValidationProvider
    v-bind="validationProviderProps"
    v-on="$listeners"
    v-slot="{ errors }"
    class="space-y-1"
    tag="div"
  >
    <PrimeDropdown
      v-bind="parentProps"
      v-on="$listeners"
      class="input w-full"
      ref="dropdown"
      @show="applyReverseTranslateY"
    >
      <template #value="slotProps">
        <slot name="value" v-bind="slotProps"></slot>
      </template>
      <template #option="slotProps">
        <slot name="option" v-bind="slotProps">
          {{ getOptionLabel(slotProps.option) }}
        </slot>
        <slot name="check" v-bind="slotProps">
          <div
            v-if="isSelected(slotProps.option)"
            class="pi pi-check absolute right-[8px] top-[calc(50%-10px)] text-sm"
          ></div>
        </slot>
      </template>
    </PrimeDropdown>
    <FormMessage v-show="errors[0]" intent="danger">{{
      errors[0]
    }}</FormMessage>
  </ValidationProvider>
</template>
