<script>
import OnboardingStepper from "@/components/ui/OnboardingStepper";
import {
  EVENT_ONBOARDING_COMPLETE,
  EVENT_ONBOARDING_NEXT_STEP,
  EVENT_ONBOARDING_PREV_STEP,
} from "@/lib/constants";
import { EventBus } from "@/lib/event-bus";

export default {
  name: "OnboardingStepsView",
  extends: OnboardingStepper,
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    nextPage(event) {
      this.formData = { ...this.formData, ...event.formData };
      // Extended from OnboardingStepper data()
      this.model[event.pageIndex].completed = true;
      this.$router.push({
        name: this.model[event.pageIndex + 1].name,
        query: event?.query,
      });
      EventBus.$emit(EVENT_ONBOARDING_NEXT_STEP, {
        model: this.model,
      });
    },
    prevPage(event) {
      // Extended from OnboardingStepper data()
      this.model[event.pageIndex].completed = false;
      this.$router.push({
        name: this.model[event.pageIndex - 1].name,
        query: event?.query,
      });
      EventBus.$emit(EVENT_ONBOARDING_PREV_STEP, {
        model: this.model,
      });
    },
    complete() {
      this.model.forEach((item) => (item.completed = true));
      EventBus.$emit(EVENT_ONBOARDING_COMPLETE, {
        model: this.model,
      });
    },
  },
};
</script>

<template>
  <div>
    <keep-alive>
      <router-view
        name="step"
        :formData="formData"
        @nextPage="nextPage($event)"
        @prevPage="prevPage($event)"
        @complete="complete"
      ></router-view>
    </keep-alive>
  </div>
</template>
