<script>
import DocumentsSentView from "@/components/views/documents-sent/DocumentsSentView.vue";

export default {
  name: "DocumentsSentPage",
  components: {
    DocumentsSentView,
  },
};
</script>

<template>
  <DocumentsSentView />
</template>
