<script>
import BaseBadge from "@/components/ui/BaseBadge";
import BaseButton from "@/components/ui/BaseButton";
import BaseCalendar from "@/components/ui/BaseCalendar.vue";
import BaseCard from "@/components/ui/BaseCard";
import BaseForm from "@/components/ui/BaseForm";
import BaseLabel from "@/components/ui/BaseLabel";
import BaseSeparator from "@/components/ui/BaseSeparator";
import BaseTextInput from "@/components/ui/BaseTextInput";
import DeliveryAddressDropdown from "@/components/ui/DeliveryAddressDropdown";
import DocumentsFileUpload from "@/components/ui/DocumentsFileUpload";
import FormGroup from "@/components/ui/FormGroup";
import PhoneNumberInput from "@/components/ui/PhoneNumberInput.vue";
import ProductCategoriesMultiselect from "@/components/ui/ProductCategoriesMultiselect.vue";
import RadioButtonWithLabel from "@/components/ui/RadioButtonWithLabel.vue";
import SaleCountriesMultiselect from "@/components/ui/SaleCountriesMultiselect.vue";
import ShippingMethodDropdown from "@/components/ui/ShippingMethodDropdown";
import TitleGroup from "@/components/ui/TitleGroup";
import agreementInformationSchema from "@/schema/agreementInformationSchema";

export default {
  name: "AgreementInformationForm",
  components: {
    BaseCard,
    TitleGroup,
    BaseBadge,
    BaseButton,
    BaseForm,
    FormGroup,
    BaseLabel,
    BaseTextInput,
    BaseSeparator,
    ShippingMethodDropdown,
    DeliveryAddressDropdown,
    RadioButtonWithLabel,
    BaseCalendar,
    DocumentsFileUpload,
    ProductCategoriesMultiselect,
    SaleCountriesMultiselect,
    PhoneNumberInput,
  },
  data() {
    return {
      ...agreementInformationSchema,
    };
  },
  methods: {
    async nextPage() {
      const isvalid = await this.$refs.form.validate();
      if (!isvalid) {
        return;
      }
      this.$emit("nextPage", {
        pageIndex: 1,
        completed: true,
        query: { isSubmissionReviewed: false },
      });
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 1, completed: false });
    },
  },
};
</script>
<template>
  <div class="space-y-6">
    <div class="space-y-4">
      <BaseBadge variant="secondary">{{
        $t("content.agreementInformation")
      }}</BaseBadge>
      <TitleGroup
        :large="true"
        :title="$t('content.fillInAgreementInformation')"
        :subtitle="$t('content.fillInAgreementInformationSubtitle')"
      />
    </div>
    <BaseCard shadow="true" variant="light" footerClassName="justify-between">
      <template #content>
        <BaseForm
          formContentClassName="space-y-4 md:space-y-6"
          :defaultSpacing="false"
          ref="form"
        >
          <template #form-content>
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{ $t("content.businessInformation") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.businessInformationSubtitle") }}
                </p>
              </div>
              <FormGroup>
                <BaseLabel for="vatNumber">{{
                  $t("input.vatNumber.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="vatNumber"
                  :fieldName="$t('input.vatNumber.fieldName')"
                  :placeholder="$t('input.vatNumber.placeholder')"
                  v-model="vatNumber"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="personalCode">{{
                  $t("input.personalCode.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="personalCode"
                  :fieldName="$t('input.personalCode.fieldName')"
                  :placeholder="$t('input.personalCode.placeholder')"
                  v-model="personalCode"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="dateOfBirth">{{
                  $t("input.dateOfBirth.label")
                }}</BaseLabel>
                <BaseCalendar
                  rules="required|date"
                  id="dateOfBirth"
                  :fieldName="$t('input.dateOfBirth.fieldName')"
                  v-model="dateOfBirth"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel>{{
                  $t("input.copyOfIndividualEconomicActivity.label")
                }}</BaseLabel>
                <DocumentsFileUpload
                  :fieldName="
                    $t('input.copyOfIndividualEconomicActivity.fieldName')
                  "
                  @change="(val) => (files = val)"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="addressOfRegistration">{{
                  $t("input.addressOfRegistration.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="addressOfRegistration"
                  :fieldName="$t('input.addressOfRegistration.fieldName')"
                  :placeholder="$t('input.addressOfRegistration.placeholder')"
                  v-model="addressOfRegistration"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="bankAccount">{{
                  $t("input.bankAccountNumber.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="bankAccount"
                  :fieldName="$t('input.bankAccountNumber.fieldName')"
                  :placeholder="$t('input.bankAccountNumber.placeholder')"
                  v-model="bankAccount"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="bankName">{{
                  $t("input.bankName.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="bankName"
                  :fieldName="$t('input.bankName.fieldName')"
                  :placeholder="$t('input.bankName.placeholder')"
                  v-model="bankName"
                />
              </FormGroup>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{ $t("content.productsInformation") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.productsInformationSubtitle") }}
                </p>
              </div>
              <FormGroup>
                <BaseLabel for="productCategories" tooltip="HelpSidebar">{{
                  $t("input.productCategories.label")
                }}</BaseLabel>
                <ProductCategoriesMultiselect
                  rules="required"
                  id="productCategories"
                  :fieldName="$t('input.productCategories.fieldName')"
                  v-model="productCategories"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel tooltip="HelpSidebar">{{
                  $t("input.numberOfSKUs.label")
                }}</BaseLabel>
                <div class="grid grid-cols-2 gap-4">
                  <RadioButtonWithLabel
                    name="numberOfSKUs"
                    label="0-100"
                    value="0-100"
                    v-model="numberOfSKUs"
                  />
                  <RadioButtonWithLabel
                    name="numberOfSKUs"
                    label="100-200"
                    value="100-200"
                    v-model="numberOfSKUs"
                  />
                  <RadioButtonWithLabel
                    rules="required"
                    :fieldName="$t('input.numberOfSKUs.fieldName')"
                    name="numberOfSKUs"
                    label="200-500"
                    value="200-500"
                    v-model="numberOfSKUs"
                  />
                  <RadioButtonWithLabel
                    name="numberOfSKUs"
                    label="500+"
                    value="500"
                    v-model="numberOfSKUs"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <BaseLabel for="saleCountries">{{
                  $t("input.saleCountries.label")
                }}</BaseLabel>
                <SaleCountriesMultiselect
                  rules="required"
                  id="saleCountries"
                  :fieldName="$t('input.saleCountries.fieldName')"
                  v-model="saleCountries"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="shippingMethod">{{
                  $t("input.shippingMethod.label")
                }}</BaseLabel>
                <ShippingMethodDropdown
                  rules="required"
                  id="shippingMethod"
                  :fieldName="$t('input.shippingMethod.fieldName')"
                  v-model="shippingMethod"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="deliveryAddress">{{
                  $t("input.deliveryAddress.label")
                }}</BaseLabel>
                <DeliveryAddressDropdown
                  rules="required"
                  id="deliveryAddress"
                  :fieldName="$t('input.deliveryAddress.fieldName')"
                  v-model="deliveryAddress"
                />
              </FormGroup>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{ $t("content.contractSignee") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.contractSigneeSubtitle") }}
                </p>
              </div>
              <FormGroup>
                <BaseLabel for="contractSigneeFullName">{{
                  $t("input.contractSignee.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="contractSigneeFullName"
                  :fieldName="$t('input.contractSignee.fieldName')"
                  :placeholder="$t('input.contractSignee.placeholder')"
                  v-model="contractSigneeFullName"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="representativeDuties">{{
                  $t("input.representativesDuties.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="representativeDuties"
                  :fieldName="$t('input.representativesDuties.fieldName')"
                  :placeholder="$t('input.representativesDuties.placeholder')"
                  v-model="representativeDuties"
                />
              </FormGroup>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{
                  $t("content.contactPersonForPerformanceAgreements")
                }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{
                    $t("content.contactPersonForPerformanceAgreementsSubtitle")
                  }}
                </p>
              </div>
              <FormGroup>
                <BaseLabel for="contactPersonPerformanceAgreementsFullName">{{
                  $t("input.contactPerson.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="contactPersonPerformanceAgreementsFullName"
                  :fieldName="$t('input.contactPerson.fieldName')"
                  :placeholder="$t('input.contactPerson.placeholder')"
                  v-model="contactPersonPerformanceAgreementsFullName"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="contactPersonDuties">{{
                  $t("input.contactPersonDuties.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="contactPersonDuties"
                  :fieldName="$t('input.contactPersonDuties.fieldName')"
                  :placeholder="$t('input.contactPersonDuties.placeholder')"
                  v-model="contactPersonPerformanceAgreementsDuties"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="email">{{ $t("input.email.label") }}</BaseLabel>
                <BaseTextInput
                  rules="email|required"
                  id="email"
                  :fieldName="$t('input.email.fieldName')"
                  :placeholder="$t('input.email.placeholder')"
                  v-model="contactPersonPerformanceAgreementsEmail"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="phoneNumber">{{
                  $t("input.phoneNumber.label")
                }}</BaseLabel>
                <PhoneNumberInput
                  rules="required|phone-number"
                  v-model="contactPersonPerformanceAgreementsPhone"
                />
              </FormGroup>
            </div>
          </template>
        </BaseForm>
      </template>
      <template #footer>
        <BaseButton
          :label="$t('button.back')"
          iconPos="left"
          icon="pi pi-angle-left"
          class="p-button p-button-secondary p-button-sm w-full text-center md:w-auto"
          @click="prevPage"
        >
        </BaseButton>
        <BaseButton
          :label="$t('button.confirmInformation')"
          class="p-button p-button-sm w-full text-center md:w-auto"
          @click="nextPage"
        >
        </BaseButton>
      </template>
    </BaseCard>
  </div>
</template>
