<script>
import TitleGroup from "@/components/ui/TitleGroup";
import FormGroup from "@/components/ui/FormGroup";
import LoginOrCreateAccountMessageLink from "@/components/ui/LoginOrCreateAccountMessageLink";
import BasePasswordInput from "@/components/ui/BasePasswordInput";
import DataProcessingAgreementCheckbox from "@/components/ui/DataProcessingAgreementCheckbox";
import BaseLabel from "@/components/ui/BaseLabel";
import BaseButton from "@/components/ui/BaseButton";
import BaseTextInput from "@/components/ui/BaseTextInput";
import BaseForm from "@/components/ui/BaseForm.vue";
import { signIn } from "@/lib/utils";

export default {
  name: "UserCredentialsFormView",
  components: {
    TitleGroup,
    FormGroup,
    LoginOrCreateAccountMessageLink,
    BasePasswordInput,
    DataProcessingAgreementCheckbox,
    BaseLabel,
    BaseButton,
    BaseTextInput,
    BaseForm,
  },
  props: {
    prevStep: {
      type: Function,
    },
  },
  data() {
    return {
      ...this.$attrs,
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      signIn();
      this.$router.push({ name: "confirm-email" });
    },
  },
};
</script>

<template>
  <div class="space-y-6">
    <TitleGroup
      :title="$t('content.createYourAccount')"
      :subtitle="$t('content.startSellingOnPhh')"
    />

    <BaseForm ref="form">
      <template #form-content>
        <FormGroup>
          <BaseLabel for="email">{{ $t("input.email.label") }}</BaseLabel>
          <BaseTextInput
            rules="email|required"
            id="email"
            :fieldName="$t('input.email.fieldName')"
            :placeholder="$t('input.email.placeholder')"
            v-model="formData.email"
          />
        </FormGroup>
        <FormGroup>
          <BaseLabel for="password">{{ $t("input.password.label") }}</BaseLabel>
          <BasePasswordInput
            id="password"
            :placeholder="$t('input.password.placeholderCreateAccount')"
            v-model="formData.password"
          />
        </FormGroup>
        <FormGroup>
          <DataProcessingAgreementCheckbox
            class="pt-2"
            v-model="formData.dataProcessingAgreement"
          />
        </FormGroup>
      </template>
    </BaseForm>

    <div class="flex gap-4">
      <BaseButton
        :label="$t('button.back')"
        iconPos="left"
        icon="pi pi-angle-left"
        class="p-button p-button-secondary p-button-sm flex-1 text-center md:w-fit md:flex-none"
        @click="prevStep"
      />
      <BaseButton
        :label="$t('button.createAccount')"
        class="p-button-sm flex-1 text-center"
        @click="handleSubmit"
      />
    </div>

    <LoginOrCreateAccountMessageLink />
  </div>
</template>
