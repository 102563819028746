<script>
import CompanyDetailsForm from "@/components/forms/CompanyDetailsForm.vue";
import BaseBadge from "@/components/ui/BaseBadge";
import BaseButton from "@/components/ui/BaseButton";
import BaseCard from "@/components/ui/BaseCard";
import BaseForm from "@/components/ui/BaseForm";
import BaseLabel from "@/components/ui/BaseLabel";
import BaseSeparator from "@/components/ui/BaseSeparator";
import BaseTextInput from "@/components/ui/BaseTextInput";
import FormGroup from "@/components/ui/FormGroup";
import PhoneNumberInput from "@/components/ui/PhoneNumberInput";
import TitleGroup from "@/components/ui/TitleGroup";
import companyDetailsSchema, {
  companyDetailsOutboundSchema,
} from "@/schema/companyDetailsSchema";

export default {
  name: "CompanyDetailsOutboundForm",
  components: {
    BaseCard,
    TitleGroup,
    BaseBadge,
    BaseButton,
    BaseForm,
    FormGroup,
    BaseLabel,
    BaseTextInput,
    BaseSeparator,
    CompanyDetailsForm,
    PhoneNumberInput,
  },
  data() {
    return {
      formData: {
        ...companyDetailsSchema,
        ...companyDetailsOutboundSchema,
      },
    };
  },
  methods: {
    async nextPage() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.$emit("nextPage", {
        formData: this.formData,
        pageIndex: 0,
        completed: true,
      });
    },
  },
};
</script>
<template>
  <div class="space-y-6">
    <div class="space-y-4">
      <BaseBadge variant="secondary">{{ $t("content.information") }}</BaseBadge>
      <TitleGroup
        :large="true"
        :title="$t('content.fillInYourPersonalAndCompanyInformation')"
        :subtitle="
          $t('content.fillInYourPersonalAndCompanyInformationSubtitle')
        "
      />
    </div>
    <BaseCard shadow="true" variant="light" footerClassName="justify-end">
      <template #content>
        <BaseForm
          formContentClassName="space-y-4 md:space-y-6"
          :defaultSpacing="false"
          ref="form"
        >
          <template #form-content>
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{ $t("content.personalInformation") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.personalInformationSubtitle") }}
                </p>
              </div>
              <FormGroup>
                <BaseLabel for="name">{{
                  $t("input.fullName.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="name"
                  :fieldName="$t('input.fullName.fieldName')"
                  :placeholder="$t('input.fullName.placeholder')"
                  v-model="formData.name"
                />
              </FormGroup>
              <FormGroup>
                <BaseLabel for="phoneNumber">{{
                  $t("input.phoneNumber.label")
                }}</BaseLabel>
                <PhoneNumberInput
                  rules="required|phone-number"
                  v-model="formData.phoneNumber"
                />
              </FormGroup>
            </div>
            <BaseSeparator />
            <div class="space-y-4">
              <div class="space-y-2">
                <strong>{{ $t("content.companyInformation") }}</strong>
                <p class="text-sm text-neutral-foreground">
                  {{ $t("content.companyInformationSubtitle") }}
                </p>
              </div>
              <CompanyDetailsForm
                :formData="formData"
                :showPhoneNumber="false"
              />
              <FormGroup>
                <BaseLabel for="website">{{
                  $t("input.websiteLink.label")
                }}</BaseLabel>
                <BaseTextInput
                  rules="required"
                  id="website"
                  :fieldName="$t('input.websiteLink.fieldName')"
                  :placeholder="$t('input.websiteLink.placeholder')"
                  v-model="formData.website"
                />
              </FormGroup>
            </div>
          </template>
        </BaseForm>
      </template>
      <template #footer>
        <BaseButton
          :label="$t('button.continue')"
          class="p-button p-button-sm w-full md:w-auto"
          @click="nextPage"
        >
        </BaseButton>
      </template>
    </BaseCard>
  </div>
</template>
