import AgreementInformationForm from "@/components/forms/AgreementInformationForm.vue";
import CompanyDetailsView from "@/components/views/CompanyDetailsView.vue";
import DocumentsSentWrapperView from "@/components/views/documents-sent/DocumentsSentWrapperView.vue";
import StartSellingView from "@/components/views/documents-sent/StartSellingView.vue";
import DownloadAndSignAgreementsWrapperView from "@/components/views/download-and-sign-agreements/DownloadAndSignAgreementsWrapperView.vue";

/**
 * @description These are the nested routes in the routes index.js /flow/onboarding path
 */
export default [
  {
    path: "step/1",
    name: "step-one",
    components: {
      step: CompanyDetailsView,
    },
  },
  {
    path: "step/2",
    name: "step-two",
    components: {
      step: AgreementInformationForm,
    },
  },
  {
    path: "step/3",
    name: "step-three",
    components: {
      step: DownloadAndSignAgreementsWrapperView,
    },
  },
  {
    path: "step/4",
    name: "step-four",
    components: {
      step: DocumentsSentWrapperView,
    },
  },
  {
    path: "step/start-selling",
    name: "start-selling",
    components: {
      step: StartSellingView,
    },
  },
];
