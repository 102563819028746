import Vue from "vue";
import VueRouter from "vue-router";

import App from "./App.vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

import { i18n, loadLanguageAsync } from "@/setup/i18n-setup";
import { getLanguage, isAuthenticated, isEmailVerified } from "./lib/utils";
import routes from "./routes";

// PrimeVue CSS
import "primevue/resources/themes/saga-blue/theme.css"; // Theme
import "primevue/resources/primevue.min.css"; // Core CSS
import "primeicons/primeicons.css";
// Tailwind CSS
import "./assets/styles/tailwind.css";
// Global styles and variables
import "./assets/styles/styles.scss";

Vue.config.productionTip = false;
Vue.use(PrimeVue);
Vue.use(ToastService);
Vue.use(VueRouter);
Vue.use(VueSvgInlinePlugin, {
  attributes: {
    remove: ["tabindex", "alt", "src", "data-src"],
  },
});

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Reset scroll position
  window.scrollTo(0, 0);

  const loadedLang = getLanguage();

  if (loadedLang) {
    await loadLanguageAsync(loadedLang);
  }

  // Redirect to /login if the user is not authenticated
  if (to.path.includes("flow") && !isAuthenticated()) {
    next({ name: "login" });
    return;
  }

  // Redirect to /confirm-email if the email is not verified
  if (
    to.path.includes("flow") &&
    to.name !== "confirm-email" &&
    !isEmailVerified()
  ) {
    next({ name: "confirm-email" });
    return;
  }

  return next();
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
