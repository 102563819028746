<script>
import BaseIcon from "@/components/ui/BaseIcon.vue";
import Sidebar from "primevue/sidebar";

export default {
  name: "BaseLabel",
  components: {
    BaseIcon,
    Sidebar,
  },
  props: {
    optional: {
      type: Boolean,
    },
    tooltip: {
      // This has to be a name of the sidebar component in @/components/sidebars/
      type: String,
    },
  },
  computed: {
    sidebarComponent() {
      if (!this.tooltip) {
        return null;
      }

      return require(`@/components/sidebars/${this.tooltip}.vue`).default;
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<template>
  <label
    class="text-sm"
    :class="(optional || tooltip) && 'flex items-center justify-between gap-2'"
  >
    <slot></slot>
    <span v-if="optional" class="text-sm text-neutral-foreground">
      ({{ $t("content.optional") }})
    </span>
    <span v-if="tooltip" class="flex text-sm text-neutral-foreground">
      <button class="h-4 w-4" @click.prevent="visible = true">
        <BaseIcon
          icon="question-fill"
          class="[&_svg>path]:fill-text-disabled [&_svg]:h-4 [&_svg]:w-4"
        />
      </button>
    </span>
    <component
      v-if="sidebarComponent && tooltip"
      v-bind:is="sidebarComponent"
      :visible.sync="visible"
    ></component>
  </label>
</template>
