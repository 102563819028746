<script>
import BaseSidebar from "@/components/ui/BaseSidebar.vue";

export default {
  name: "HelpSidebar",
  components: {
    BaseSidebar,
  },
  computed: {
    props() {
      return { ...this.$attrs, ...this.$props };
    },
  },
};
</script>

<template>
  <BaseSidebar v-bind="props" v-on="$listeners">
    <div class="space-y-4">
      <div class="space-y-2">
        <p>{{ $t("sidebar.productCategoryHelp.primaryTitle") }}</p>
        <p class="text-sm text-neutral-foreground">
          {{ $t("sidebar.productCategoryHelp.primaryContent") }}
        </p>
      </div>
      <div class="space-y-2">
        <p>
          {{ $t("sidebar.productCategoryHelp.secondaryTitle") }}
        </p>
        <p class="text-sm text-neutral-foreground">
          {{ $t("sidebar.productCategoryHelp.secondaryContent") }}
        </p>
      </div>
    </div>
  </BaseSidebar>
</template>
