<script>
import PhhGroupLogo from "@/assets/images/logos/phh-group-logo.png";
import LanguageSelect from "@/components/ui/LanguageSelect";
import BaseButton from "@/components/ui/BaseButton";
import {
  cn,
  isAuthenticated,
  isInternalFlow,
  isOnboarding,
  signOut,
} from "@/lib/utils";
import BaseSeparator from "@/components/ui/BaseSeparator.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import OnboardingStepper from "@/components/ui/OnboardingStepper.vue";

export default {
  name: "BaseHeader",
  components: {
    BaseButton,
    LanguageSelect,
    BaseSeparator,
    BaseIcon,
    OnboardingStepper,
  },
  data() {
    return {
      logo: PhhGroupLogo,
    };
  },
  computed: {
    isAuthenticated() {
      return isAuthenticated(this);
    },
    isOnboarding() {
      return isOnboarding(this);
    },
    isInternalFlow() {
      return isInternalFlow(this);
    },
    className() {
      return cn(
        "grid grid-cols-2 w-full z-10 gap-6 md:py-6 md:px-8 px-4 py-3 items-center bg-transparent md:absolute",
        [
          this.isAuthenticated &&
            this.isInternalFlow &&
            "grid-cols-2 sticky md:sticky top-0 bg-white shadow border-b md:py-3 md:px-4",
        ],
        [
          this.isAuthenticated &&
            this.isOnboarding &&
            "grid-cols-2 md:grid-cols-[1fr,minmax(auto,490px),1fr]",
        ]
      );
    },
  },
  methods: {
    handleSignOut() {
      signOut();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<template>
  <header :class="className">
    <div class="flex-grow">
      <img
        :src="logo"
        alt="PHH Group logo"
        class="max-w-[116px] invert md:max-w-[124px]"
      />
    </div>
    <div class="hidden md:block" v-if="isAuthenticated && isOnboarding">
      <OnboardingStepper />
    </div>
    <div class="flex flex-grow justify-end">
      <div class="flex justify-between gap-4 lg:gap-2 xl:gap-4">
        <LanguageSelect />
        <BaseSeparator
          orientation="vertical"
          v-if="isAuthenticated && isInternalFlow"
        />
        <BaseButton
          @click="handleSignOut"
          v-if="isAuthenticated && isInternalFlow"
          class="p-button p-button-sm p-button-secondary flex max-w-10 items-center gap-2 font-semibold lg:max-w-full"
        >
          <BaseIcon icon="sign-out" class="h-5 min-w-5 max-w-5" />
          <span
            class="hidden flex-shrink-0 flex-grow self-end lg:inline-block"
            >{{ $t("button.signOut") }}</span
          >
        </BaseButton>
      </div>
    </div>
  </header>
</template>
