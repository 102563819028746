<script>
import BaseIcon from "@/components/ui/BaseIcon";
import BaseButton from "@/components/ui/BaseButton";
import BaseDialog from "@/components/ui/BaseDialog";
import FormGroup from "@/components/ui/FormGroup";
import BaseLabel from "@/components/ui/BaseLabel";
import BaseTextarea from "@/components/ui/BaseTextarea";
import BaseTextInput from "@/components/ui/BaseTextInput";
import BaseForm from "@/components/ui/BaseForm";
import { TOAST_LIFE } from "@/lib/constants";
import { cn } from "@/lib/utils";

const initialValues = {
  email: null,
  isEmailValid: false,
  message: null,
};

export default {
  name: "BaseFooter",
  components: {
    BaseIcon,
    BaseButton,
    BaseDialog,
    FormGroup,
    BaseLabel,
    BaseTextarea,
    BaseForm,
    BaseTextInput,
  },
  props: {
    className: {
      type: String,
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showModal: false,
      formData: {
        ...initialValues,
      },
    };
  },
  computed: {
    footerClassName() {
      return cn(
        "pointer-events-none static bottom-0 left-0 z-10 flex w-full items-end justify-between bg-transparent px-4 py-3 md:fixed md:px-8 md:py-6",
        this.className
      );
    },
  },
  methods: {
    onSubmit() {
      this.formData = initialValues;
      this.showModal = false;
      this.$toast.add({
        severity: "success",
        detail: this.$t("toast.contactUs.contactUsSuccess"),
        life: TOAST_LIFE,
      });
    },
    handleSubmit() {
      this.$refs.form.$el.firstChild.requestSubmit();
    },
  },
};
</script>

<template>
  <footer :class="footerClassName">
    <div>
      <span class="text-sm text-neutral-foreground">{{ year }} © pigu.lt</span>
    </div>
    <div class="fixed bottom-3 right-4 z-[1300] md:bottom-6 md:right-8">
      <BaseDialog
        :header="$t('content.contactUs')"
        appendTo="chatModalBox"
        :visible.sync="showModal"
        :modal="true"
      >
        <BaseForm :onSubmit="onSubmit" ref="form">
          <template #form-content>
            <FormGroup>
              <BaseLabel for="email">{{ $t("input.email.label") }}</BaseLabel>
              <BaseTextInput
                rules="required|email"
                id="email"
                :fieldName="$t('input.email.fieldName')"
                :placeholder="$t('input.email.placeholder')"
                v-model="formData.email"
              />
            </FormGroup>
            <FormGroup>
              <BaseLabel for="message">{{
                $t("input.contactUsMessage.label")
              }}</BaseLabel>
              <BaseTextarea
                rules="required"
                id="message"
                :fieldName="$t('input.contactUsMessage.fieldName')"
                :placeholder="$t('input.contactUsMessage.placeholder')"
                rows="3"
                v-model="formData.message"
                :autoResize="true"
              />
            </FormGroup>
          </template>
        </BaseForm>
        <template #footer>
          <BaseButton class="p-button p-button-sm" @click="handleSubmit">{{
            $t("button.sendMessage")
          }}</BaseButton>
        </template>
      </BaseDialog>
      <div
        id="chatModalBox"
        v-if="showModal"
        class="pointer-events-none fixed bottom-0 left-0 top-0 z-[1100] w-full overflow-clip md:bottom-[72px] md:left-auto md:right-[32px] md:top-auto md:h-[420px] md:max-w-[400px] md:rounded-xl"
      ></div>
      <BaseButton
        @click="showModal = true"
        class="p-button p-button-sm p-button-icon p-button-secondary pointer-events-auto flex items-center gap-2 text-sm font-semibold"
      >
        <BaseIcon icon="envelope-simple" class="w-5" />
        <span class="hidden md:inline">{{ $t("button.needHelp") }}</span>
      </BaseButton>
    </div>
  </footer>
</template>
