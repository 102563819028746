<script>
import PrimeRadioButton from "primevue/radiobutton";

export default {
  name: "BaseRadio",
  extends: PrimeRadioButton,
  components: {
    PrimeRadioButton,
  },
  computed: {
    parentProps() {
      return { ...this.$props, ...this.$attrs };
    },
  },
};
</script>

<template>
  <PrimeRadioButton v-bind="parentProps" v-on="$listeners" />
</template>
